@import "hero";

.interior-hero{
    height: rem(475px);
    width: 100vw;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position-y:60%;
    background-attachment: scroll;
    background-position: center;
    -webkit-background-size: cover;
    z-index: 1;

        &__background-image-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-size: cover;
        background-position: 50% 50%;

        // Overlay modifier
        &--overlay:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba($color--brand-gold, 0.86) 0%, rgba($color--black, 0.86) 100%);
        }

        // Firefox modification
        body:last-child &--overlay:after, x:-moz-any-link {
            background-image: -moz-linear-gradient(90deg, rgba($color--black, .6), rgba($color--black, 0));
        }
    }

    .hero-container{
        top: calc(50% + #{$header-height-desktop} / 2);
        transform: translateY(-50%);
        position: absolute;
        text-align: center;
        width: 100%;
    }
    &.page-not-found{
        height: 30vh;
        width: initial;
        margin: 5rem;
        top: calc(35% + #{$header-height-desktop} / 2);
        @include screen($bp--phone){
            margin: 0;
            min-height: 40vh;
            height: inherit;
        }
    }
    h1.heading{
        font-size: rem(72px);
        margin-bottom: rem(40px);
        max-width: 100%;
        @include font-serif-bold;

        &--white {
            color: $color--white;            
        }
    }

    h1.capabilities-detail-header {
        font-size: rem(47px);
        max-width: initial;
        padding: 0 15% 0 15%;
        color: $color--white;

        &--white {
            color: $color--white;            
        }
    }

    .tagline{
        @include font-sans-serif;
        font-size: rem(20px);
        max-width: 400px;
        display: inline-block;
        line-height: 1.4;
        p{
            color: $color--black;
        }

                &--white {
            color: $color--white;   
                    p{
            color: $color--white;
            }         
        }

    }

    .btn-link {
        margin-top: 2rem;

        &::before {
            border-color: $color--black !important;
        }

        @include filler-link(
            $background-color--initial: $color--black,
            $color---initial: $color--floral-white,
            $background-color--hover: $color--floral-white,
            $color---hover: $color--black
        );

        @include font-sans-serif-bold;

        // Modifier for the option to include a white header and gold text/border button
        // https://onenorthi.atlassian.net/browse/AM-3323
        &__gold {
            border: 2px solid $color--brand-gold;
            color: $color--floral-white;
            background-color: transparent;

            &:before {
                background-color: transparent;
                border: none;
            }

            &::after {
                background-color: $color--brand-gold;
            }
        }
    }

    .buttons{
        display: inline-block;

        .btn-link{
            display: inline-flex;
        }

        .filter-section-select{
            background-color: $color--white;
            width:20%;
        }
    }

    .drop-down {
        margin-top: 1rem;

        .current-selection {
            font-size: rem(18px);
            padding: .5rem 1rem;
            background-color: rgba($color--white, .75);
            border: 1px solid $color--white;

            &:after {
                width: .4em;
                height: .4em;
                position: relative;
                top: 3px;
            }
        }

        .drop-down-options {
            width: 75vw;
            max-height: 40vh;
            min-width: rem(300px);
            max-width: rem(600px);
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            overflow: auto;
            border-color: $color--white;
        }

        .drop-down-option {
            font-size: rem(18px);
        }
    }

    @include screen($bp--phone){
        h1.heading {
            font-size: 3.5rem;
        }
        .tagline{
            display: none;
        }
    };
    @include screen($bp--phone-small){
        h1.heading {
            font-size: 3rem;
        }
    }
}
