﻿.language-select {
    text-align: right;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        height: 1em;
        width: 2px;
        background-color: $color--black;
    }

    .drop-down {
        display: inline-block;
        padding-right: 0.75em;
    }

    .current-selection {
        padding-left: .325em;
        font-size: 16px;
        width: 100%;

        &:before {
            font-size: .75em;
            margin: .25em .5em 0 0;
            position: relative;
            top: -3px;
        }
    }

    .drop-down-options {
        width: auto;
        left: auto;
        right: 0;
        background-color: $color--white;
        z-index: 1;
    }

    .drop-down-option {
        white-space: nowrap;
    }

    @include screen($bp--site-nav-break) {
        text-align: left;
        margin-top: 1rem;
        font-size: 1.35rem;
        margin-left: .1em;
        z-index: 100;
        position: absolute;
        bottom: 100%;
        left: 5%;
        color: $color--black;


        &:before {
            content: none;
        }

        .current-selection {
            padding: 0;
            text-transform: uppercase;
            letter-spacing: 1px;

            &:before {
                margin: 0;
            }

            &:after {
                font-size: .75em;
                position: relative;
                top: 2px;
                border-color: $color--black;
            }
        }

        .drop-down {
            margin-left: 0;
        }

        .drop-down-options {
            left: 0;
            right: auto;
            background-color: $color--floral-white;
        }

        .drop-down-option {
            &:hover {
                color: $color--black;
            }
        }
    }

    .white-text:not(.site-nav--opaque) & {
        color: $color--white;

        &:before {
            background-color: $color--white;
        }

        .current-selection {
            &:before {
                border-color: $color--white;
            }

            &:after {
                border-color: $color--white;
            }
        }

        .drop-down-options {
            background-color: $color--black;
        }

        .drop-down-option {
            &:hover {
                color: $color--black;
            }
        }
    }
}
