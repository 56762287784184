.insights-detail-header {
    $padding: rem(120px);

    background-color: $color-gray-darkest;
    padding: rem($header-height-desktop) + $padding $site-padding $padding;

    .full-width-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .article-date {
        color: $color--white;
        margin-right: .5rem;
        @include font-sans-serif-bold;
        &:after {
            content: "|";
            color: $color--brand-gold;
            margin: 0px 3px 0 10px;

        }
    }

    .article-type {
        text-transform: uppercase;
        color: $color--white;
        @include font-sans-serif-bold;
    }

    .article-source{
        font-style: italic;
        color: #fafaf0;
        display: inline-block;
        &:before {
            content: '|';
            color: $color--brand-gold;
            margin: 0px 10px 0px 10px;
            font-style: normal;
            font-weight: bold;
        }
    }

    .insight-title {
        color: $color--white;
        font-size: rem(40px);
        line-height: 1.25;
        letter-spacing: 0px;
        margin-top: 1rem;

        @include font-serif-bold;
    }

    .article-authors {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    .authors-label {
        color: $color--white;
        font-size: rem(20px);
        margin-right: .5rem;
    }

    .author-link {
        color: $color--brand-gold;
        font-size: rem(20px);

        &:after {
            content: '|';
            color: $color--white;
            margin: 0 .5rem;
        }

        &:last-of-type {
            &:after {
                content: none;
            }
        }
    }

    .related-links {
        margin-top: 1rem;
    }

    .inline-list {
        max-width: inherit;

        .label-section {
            color: $color--white;
            font-weight: 400;
            line-height: 1;
        }

        .list-section {
            margin-bottom: 0;
        }

        .list-item-link {
            color: $color--brand-gold;
            background: none;
            font-weight: 400;
            transition: color 0.4s ease-in-out;

            &:hover {
                color: $color--floral-white;
            }

            + .list-item-link {
                &:before {
                    color: $color--white;
                }
            }
        }
    }

    @include screen($bp--tablet) {
        padding-left: 2.5vw;
        padding-right: 2.5vw;
    }

    @include screen($bp--phone) {
        $padding: rem(90px);
        padding: rem($header-height-desktop) + $padding 1rem $padding;

        .insight-title {
            font-size: rem(36px);
        }
    }

    @include screen($bp--phone-small) {
        $padding: rem(60px);
        padding: rem($header-height-desktop) + $padding 1rem $padding;

        .insight-title {
            font-size: rem(32px);
        }
    }
}
