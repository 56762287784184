.contact-module {
    .content-grid {
        display: flex;
    }

    .grid-column {
        $spacing: 3rem;

        box-sizing: border-box;

        &:first-of-type {
            width: 60%;
            padding-right: $spacing;
            border-right: 1px solid $color--black;
        }

        &:last-of-type {
            width: 40%;
            padding-left: $spacing
        }
    }

    .input-field {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 2rem;
        border-bottom: 1px solid $color--black;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .field-label {
        @include font-serif-bold;
    }

    .text-input,
    .drop-down {
        flex-grow: 1;
        margin-left: 1rem;
    }

    .text-area {
        display: block;
        width: 100%;
        margin-top: 1rem;
    }

    .btn-submit {
        float: right;
        margin-top: 2rem;

        @include filler-link(
            $background-color--initial: transparent,
            $color---initial: $color--black,
            $background-color--hover: $color--brand-gold,
            $color---hover: $color--black
        );
    }

    .contact-info {
        margin-top: 4rem;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .contact-title {
        font-size: rem(32px);
        line-height: 1.2;

        @include font-serif-bold;
    }

    .contact-summary {
        font-size: rem(20px);
        line-height: 1.5;
        p{
             &:first-child{
                 margin-top: 0;
             }
        }
    }

    .contact-link {
        margin-top: 1.5rem;

        @include font-sans-serif-bold;

        @include filler-link(
            $background-color--initial: transparent,
            $color---initial: $color--black,
            $background-color--hover: $color--cornflower-blue,
            $color---hover: $color--black
        );
    }

    @include screen($bp--phone){
        .content-grid {
            flex-direction: column;
        }
        .grid-column {
            &:first-of-type,
            &:last-of-type {
                width: 100%;
                padding: 0;
                border-right: none;
            }

            &:last-of-type {
                $spacing: 3rem;

                border-top: 1px solid $color--black;
                padding-top: $spacing;
                margin-top: $spacing;
            }
        }
    }
}
