.page-navigation{
    $transition-duration: .4s;
    $page-nav-width:250px;
    position: absolute;
    left: 0;
    top: 4.5rem;
    width: $page-nav-width;
    transition: opacity $transition-duration .01s;

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
        transition: opacity $transition-duration, visibility 0.01s $transition-duration;
    }

    .nav-mobile-prompt {
        display: none;
    }

    .nav-item,
    .nav-link {
        @include filler-link(
            $background-color--initial: $color--white,
            $color---initial: $color--black,
            $background-color--hover: $color--brand-gold,
            $color---hover: $color--black
        );
        box-sizing: border-box;
        text-align: left;
        width: 100%;
        display: block;
        font-size: rem(16px);
        @include font-sans-serif-bold;
        text-transform: uppercase;
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: 60px;
        padding: 0 0 0 60px;
        border-bottom: solid 1px $color--gondola;
        cursor: pointer;

        &.active{
            background-color: $color--brand-gold;
            &:before{
                background-color: $color--brand-gold;
            }
        }
        &:before{
            border: none;
            background-color: $color--white;
        }
        &:first-child{
            border-top: solid 1px $color--gondola;
        }
    }

    .nav-link {
        color: $color--white;

        &:before {
            background-color: $color--black;
        }
        &:hover {
            background-color: $color--brand-gold;
        }
    }

    .expand-all{
        @include filler-link(
            $background-color--initial: $color--white,
            $color---initial: $color--black,
            $background-color--hover: $color--brand-gold,
            $color---hover: $color--black
        );

        margin: rem(30px) 2.5rem;
        display: block;
        text-align: center;
        border: solid 2px $color--brand-gold;
        font-weight: bold;
        &:before{
            border: none;
            //background-color: rgba(255,255,255,.5);
        }
        @include screen($bp--tablet){
            margin: rem(30px) 2rem;
        }
    }
    @include screen($bp--tablet){
        width: 200px;
        .nav-item{
            padding-left: 30px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    };

    @include screen($bp--phone, min) {
        &.fixed{
            position: fixed;
            top: 95px;
        }
    }

    @include screen($bp--phone){
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transition: transform 0.65s;
        z-index: 1;

        &.hide-page-nav-mobile {
            transform: translate3d(0, -100%, 0);
        }

        .nav-mobile-prompt {
            display: block;
            background-color: $color--brand-gold;
            color: $color--white;
            text-transform: uppercase;
            padding: 1.5rem 1rem;

            &:after {
                $size: .5em;

                content: '';
                display: inline-block;
                width: $size;
                height: $size;
                border: 2px solid $color--white;
                border-top: none;
                border-left: none;
                float: right;
                transform: rotate(45deg);
            }
        }
        .navigation-area {
            background-color: $color--white;
            display: none;
        }

        .expand-all,
        .nav-link {
            display: none;
        }

        &.show-quick-links {
            .nav-mobile-prompt {
                &:after {
                    transform: rotate(-135deg);
                }
            }

            .navigation-area {
                display: block;
            }
        }
    };
}
