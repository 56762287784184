.page-locations-detail {
    position: relative;
    $spacing: rem(72px);

    .panel {
        padding: $spacing 0;

        > .content-wrapper-right > * {
            margin-top: $spacing;

            &:first-child {
                margin-top: 0;
            }
        }

        &:nth-of-type(odd) {
            background-color: $color--white;
        }

        &:nth-of-type(even) {
            background-color: $color-gray-darkest;
        }
    }

    .interior-infographic {
        background-color: transparent;
        padding: 0;
    }

    .related-content {
        padding: 0;
        width: auto;
    }
}
