.related-capabilities {
    .capability-type {
        font-size: rem(18px);
        margin-top: 3rem;

        @include font-serif-bold;
    }

    .capabilities-list {
        columns: 2;
        margin-top: 2rem;
    }

    .capability-item {
        $left-margin: 0.75em;

        padding: .25rem 0;
        position: relative;
        line-height: 1.5;
        display: inline-block;
        width: 95%;
        padding-left: 1rem;

        &:before {
            content: '•';
            display: inline-block;
            position: absolute;
            left: 0;
            line-height: 1.5;
        }

        @include screen($bp--tablet) {
            margin-left: 0;
        }

        @include screen($bp--phone) {
            margin-left: 1em;
        }
    }

    .capability-link {
        @include font-sans-serif;
        font-size: rem(18px);
        color: $color--bistro-brown;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;

        &:hover {
            text-decoration: none;
            color: $color--black;
        }

        @include screen($bp--tablet) {
            font-size: rem(16px);
        }

        @include screen($bp--phone) {
            font-size: rem(18px);
        }
    }
}
