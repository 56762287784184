.page-locations-landing {
    $spacing: rem(72px);

    .panel {
        background-color: $color--white;
        padding: $spacing 0;

        > .full-width-wrapper > * {
            margin-top: $spacing;

            &:first-child {
                margin-top: 0;
            }
        }

        &:nth-of-type(2) {
            padding-top: $spacing - rem(20px);
            background-color: $color--white;
        }

        &.infographic-container {
            background-color: $color--floral-white;
        }
    }

    .interior-infographic {
        background-color: transparent;
        padding: 0;
    }
}
