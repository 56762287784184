// Vars
@use "sass:math";

$home-infographic-one__carousel-button-list__before: 1;

// Block
.home-infographic-one {
    background-color: $color--black;
    padding: 3% 9.4%;

    @media (max-width: $bp--tablet) {
        text-align: center;
    }

    @media (max-width: $bp--tablet) {
        padding: 3rem;
    }

    .lazy-show--from-left,
    .lazy-show--from-right {
        transition: opacity 1s 1s,
            transform 1s 1s;
    }
}

// Element
.home-infographic-one__infographic-container {
    margin:auto;
    margin-top: 4.1rem;
    width: 73%;
    @media (max-width: $bp--phone){
        display: none;
    }
}

.homepage-infographic-one__chart-carousel {
    padding-left: 8rem;
    position: relative;

    .slick-dots {
        position: absolute;
        top: 45%;
        left: 0;
        transform: translate3d(0, -50%, 0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 4rem;
        cursor: pointer;

        li {

            &:last-of-type {
                margin-bottom: 0;
            }

                     &:before{
                        content: '|';
                        margin-right: 0.5rem;
                        font-size: 6rem;
                        color: $color-gray-darkest;
                    }

            &.slick-active {
                    &:before{
                        color: $color--brand-gold;
                    }
                a{
                    display: inline-block;
                    color: $color--brand-gold;
                    font-size: 3rem;
                    vertical-align: top;
                    padding-top: 50%;
                }
                button {
                    background-color: $color--black;
                    position: relative;

                    &:before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        background-color: rgba($color--brand-gold, .5);
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 50%;
                        height: 50%;
                        border-radius: 50%;
                        background-color: $color--brand-gold;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }

        button {
            $size: 1rem;

            position: relative;
            display: block;
            width: $size;
            height: $size;
            border-radius: 50%;
            border: 1px solid $color--brand-gold;
            background-color: $color--black;
            transition: background-color .3s;
            color: rgba($color--black, 0);
        }
    }

    @media (max-width: $bp--tablet) {
        padding-left: 0;

        .slick-dots {
            display: inline-flex;
            position: relative;
            transform: none;
            flex-direction: row;
            margin-top: 2rem;
            width:100%;
            cursor: pointer;

            &:before {
                width: 100%;
                height: 1px;
                top: 50%;
                left: 0;    
            }

            li {                
                margin-bottom: 0;
                margin-right: 3rem;
                padding-left: 2rem;
                a{
                vertical-align: baseline;
                padding-top: 0;
                }
                &:before{
                    display: block;
                    transform: rotate(90deg);
                }
                &:last-of-type {
                    margin-right: 0;
                }

                &.slick-active {
                    a{
                        
                vertical-align: baseline;
                padding-top: 0;
                    }
                    }
            }
        }

    }
}

.homepage-infographic-one__chart {
    &.slick-slide {
        visibility: hidden;
    }

    &.slick-current {
        visibility: visible;
    }
}

.home-infographic-one__infographic--practices,
.home-infographic-one__infographic--cities {
    min-height: 500px;
}

.home-infographic-one__cta {
    @include filler-link;

    margin-top: 1.3rem;

    @include screen($bp--phone){
        margin-top: 2rem;
    }
}

.home-infographic-one__cta-container {
    margin-top: 0.6rem;
    text-align: center;
    padding-left: 4rem;
    @include screen($bp--phone) {
        padding-left: 0rem;
    }
}

.home-infographic-one__heading {
    color: $color--brand-gold;
    font-size: 2rem;
    line-height: 1.10;
    @include screen($bp--phone){
        padding-bottom: 2rem;
    }
}

.home-infographic-one__description {
    color: $color--white;
    margin-top: 0;
    line-height: 1.4;
    font-size: 1.25rem;

    @media (max-width: $bp--tablet) {
        margin-top: 3rem;
    }
    @media (max-width: $bp--phone) {
        $mobile-base-font: 14;

        font-size: math.div(1rem * 18, $mobile-base-font);
        line-height:  math.div(1rem * 32, $mobile-base-font);
    }
}

.home-infographic-one__text-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: $bp--tablet) {
        display: block;
    }

    > * {
        flex-basis: 47.5%;
    }
}
