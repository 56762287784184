.search-filters {
    position: relative;
    $field-height: 150px;

    .search-content {
        background-color: $color--brand-gold;
        height: $field-height;
    }

    .search-field-container {
        padding-left: $site-padding;
    }

    .search-field {
        $icon-size: 18px;
        @extend %vertical-align-center;
        position: absolute;
        width: calc(50% - 12.7vw - 40px);

        input[type=text] {
            font-size: rem(24px);
            @include font-serif;
            color: $color--black;
            height: rem(32px);
            padding: 4px 0;
            width: calc(100% - #{$icon-size});
        }

        &__button {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 18px;
            height: 38px;
        }

        .icon {
            fill: $color--black;
            height: $icon-size;
            width: $icon-size;
            position: absolute;
            right: 0;
            top: 8px;
            cursor: pointer;
            transition: transform 0.4s;

            &:hover {
                transform: scale(1.25);
            }
        }
        
        @include screen($bp--tablet) {
            width: calc(56% - 12.7vw - 40px);
        }
    }

    .text-input {
        border-bottom: 2px solid $color--white;
    }

    .radio-input {
        margin-top: 1rem;
    }

    .radio-button-group {
        white-space: nowrap;
        display: inline-block;
        margin: .25em 1rem .25em 0;

        &:last-of-type {
            margin-right: 0;
        }
    }

    .radio-button {
        opacity: 0;

        &:focus {
            + .radio-button-label {
                &:before {
                    border: 2px solid $color--cornflower-blue;
                }
            }
        }
        

        &:checked {
            + .radio-button-label {
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .radio-button-label {
        $size: 1em;
        $spacing: $size + .5em;
        margin-left: $size * -1;
        padding-left: $spacing;
        white-space: nowrap;
        position: relative;
        cursor: pointer;
        color: $color--black;
        font-size: rem(16px);

        &:before {
            box-sizing: border-box;
            content: '';
            display: inline-block;
            width: $size;
            height: $size;
            border-radius: 50%;
            position: absolute;
            left: $size * 0.5;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            border: 2px solid $color--white;
        }

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            left: $size*0.5;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: $size * .5;
            height: $size * .5;
            border-radius: 50%;
            background-color: $color--black;
            opacity: 0;
            transition: opacity .2s;
        }
    }

    .filter-headings {
        display: inline-block;
        width: calc(50% + 40px);
        height: 100%;
        padding: 0 $site-padding 0 0;
        background-size: cover;
        right: -4px;
        position: absolute;
        text-align: right;
        color: $color--white;

        @include screen($bp--phone) {
            padding: 0 0 0 0;
            right: 0;
        }

        &:before {
            content: '';
            display: inline-block;
            border-left: 150px solid $color--brand-gold;
            border-bottom: 150px solid transparent;
            position: absolute;
            left: -1px;

            @include screen($bp--phone) {
                content: none;
            }
        }

        @include screen($bp--tablet) {
            width: calc(44% + 40px);
        }
    }

    .filter-container {
        @extend %vertical-align-center;
        position: relative;
        max-width: calc(100% - 125px);
        width: auto;
        right: -25px;
        float: right;

        .filter-container-heading {
            font-size: rem(24px);
            text-align: left;
        }
    }

    .filter-label {
        font-size: rem(20px);
        color: $color--white;
        margin-right: 24px;
        display: inline-block;
        cursor: pointer;
        transition: $transition-text-color;
        line-height: 2;

        &:hover, &.active {
            color: $color--brand-gold;
        }

        &.active {
            &:after {
                transform: rotate(90deg);
                top: 4px;
            }
        }

        &:after {
            content: $icon-angle-right;
            font-family: $icomoon-font-family;
            font-size: rem(18px);
            color: $color--brand-gold;
            position: relative;
            margin-left: 12px;
            top: 2px;
            transition: transform 0.3s ease-in-out;
            display: inline-block;
        }
    }

    .btn-apply {
        float: right;
        margin-top: 2rem;

        @include filler-link( $background-color--initial: $color--brand-gold, $color---initial: $color--white, $background-color--hover: $color-gray-darkest, $color---hover: $color--white );
    }

    @include screen($bp--phone) {
        .search-content {
            height: 2 * $field-height;
        }

        .search-field {
            position: relative;
            width: calc(100% - #{$site-padding});
        }

        .search-field-container {
            height: $field-height;
        }

        .filter-headings {
            position: relative;
            width: 100%;
            height: $field-height;
            top: 0;
            float: none;
            display: block;
            text-align: center;
        }

        .filter-container {
            width: auto;
            max-width: 100%;
            left: 0;
            right: 0;
            float: none;

            .filter-container-heading {
                text-align: center;
            }
        }
    }
}
