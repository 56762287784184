.drop-down, .drop-down-alumni {
    $line-height: 1;

    position: relative;
    cursor: pointer;

    .current-selection {
        min-height: 1em * $line-height;

        &:after {
            $size: .5em;

            content: '';
            display: inline-block;
            width: $size;
            height: $size;
            border: 2px solid black;
            vertical-align: middle;
            float: right;
            transform: rotate(45deg);
            border-top: none;
            border-left: none;
            margin-left: 0.5em;
            margin-top: 0.15rem;
        }
    }

    .drop-down-options {
        border: 1px solid $color-gray-darkest;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $color--white;
        opacity: 0;
        display: none;
        transition: opacity 0.5s ease-out;
    }

    .drop-down-option {
        padding: 0.5rem 1rem;
        line-height: $line-height;
        min-height: $line-height * 1em;

        &:hover {
            background-color: $color--floral-white;
        }

        &.is-selected {
            display: none;
            box-sizing: border-box;
        }
    }

    &.show-options {
        .drop-down-options {
            opacity: 1;
            display: block;
        }

        .current-selection {
            &:after {
                transform: rotate(-135deg);
                position: relative;
                top: 5px;
            }
        }
    }

    &.arrow-left {

        .current-selection {
            &:after {
                content: none;
            }

            &:before {
                $size: .5em;

                content: '';
                display: inline-block;
                width: $size;
                height: $size;
                border: 2px solid black;
                vertical-align: middle;
                transform: rotate(45deg);
                border-top: none;
                border-left: none;
                margin-right: .5em;
            }
        }
    }
}

.drop-down-alumni {
    .drop-down-options {
        left: -50%;
        width: 200%;
    }
}

.drop-down-language {
    .drop-down-option {
        padding: 0;

        a {
            padding: .5rem 1rem;
            display: block;
        }
    }
}
