.page-home {
    background-color: $color--white;
    width: 90%;
    margin: auto;

    @media (max-width: $bp--tablet) {
        width: auto;
        padding: 0 5% 0;
        margin-top: -50%;
    }

    .highlight-tiles-section, .topics-tiles-section {
        padding-top: 2rem;
    }

    @include screen(600px) {

        .highlight-summary.home-infographic-one__description {
            padding: rem(35px) !important;
        }
    }
}
