.page-search {
    .insight-list {
        .search-criteria {
            color: $color--white;
            padding: 3.75rem 1rem 0 0;
            display: inline-block;
        }
    }

    .filter-container {
        text-align: left;

        .filter-label {
            &:after {
                content: none;
            }
        }

        &:hover {
            color: $color--white;
        }
    }

    .filter-options {
        margin-top: .5rem;
    }

    .btn-filter-option {
        @include filler-link( $background-color--initial: transparent, $color---initial: $color--white, $background-color--hover: $color--brand-gold, $color---hover: $color--black ); &.is-active {
            @include filler-link( $background-color--initial: $color--brand-gold, $color---initial: $color--black, $background-color--hover: $color--brand-gold, $color---hover: $color--black );
        }

        &,
        &.is-active {
            font-size: rem(12px);
            padding: .5rem 1rem;
            margin: 0 .5rem .5rem 0;
        }
    }

    .clear-button {
        &:after {
            content: 'X';
            font-size: 1.25rem;
            color: $color--black;
        }

        &:hover {
            transform: scale(1.25);
        }
    }

    .keyword-search {
        width: calc(100% - 45px) !important;
    }

    .filter-checkbox {
        height: 1.25rem;
        width: 1.25rem;
    }

    .result-count-section {
        width: 100%;
        padding-left: 6.35vw;
        padding-right: 6.35vw;
    }

    .result-filter-section {
        display: none;
    }

    @include screen($bp--tablet) {
        $field-height: 150px;

        .search-content {
            height: 2 * $field-height;
        }

        .search-field {
            position: relative;
            width: calc(100% - #{$site-padding});
        }

        .search-field-container {
            height: $field-height;
        }

        .filter-headings {
            position: relative;
            width: 100%;
            height: $field-height;
            top: 0;
            float: none;
            display: block;
            text-align: center;
            padding: 0 0 0 0;
            right: 0;

            &:before {
                content: none;
            }
        }

        .filter-container {
            padding: 0 6.35vw;
            width: auto;
            max-width: 100%;
            left: 0;
            right: 0;
            float: none;

            .filter-container-heading {
                text-align: center;
            }
        }
    }
}
