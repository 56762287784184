// Block
.site-footer {
    position: relative;
    z-index: -99999;

    visibility: hidden;
    
    &.active {
        visibility: visible;
    }
}

.site-footer-home {
    background-color: $color--black;
}


// Element
.site-footer__social-icon-list-item {
    display: block;
    margin-right: 0.75rem;

    @media (max-width: $bp--phone) {
        $margin-horizontal: 0.4rem;

        margin-left: $margin-horizontal;
        margin-right: $margin-horizontal;
    }
}

.site-footer__social-icon-list {
    display: flex;

    @media (max-width: $bp--phone) {
        justify-content: center;
    }
}

.site-footer__social-icon-link {
    .icon {
        $height: 1.5rem;

        display: block;
        height: $height;
        width: $height;

        &:hover {
            opacity: 0.85;
        }
    }
}

.site-footer__small-print-container {
    color: $color--brand-gold;
    margin-top: 1.9rem;

    @include screen($bp--phone) {
        text-align: center;
    }
}

.site-footer__small-print-container-desktop {
    @include screen($bp--phone) {
        display: none;
    }
}

.site-footer__small-print-container-mobile {
    display: none;

    @include screen($bp--phone) {
        display: block;
    }
}

.site-footer__small-print {
    color: $color--white;
    font-size: 0.8125rem;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: 0;

    a {
        text-decoration: underline;
    }

    @media (max-width: $bp--phone) {
        line-height: 1.2;
        margin-left: auto;
        margin-right: auto;
    }
}

.site-footer__link-list-link {
    $color: $color--brand-gold;

    @include underline-link($color);
    color: $color;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

.site-footer__column--right {
    align-items: flex-start;
    align-self: flex-start;
    color: $color--white;
    display: flex;
    flex: 1 75%;
    flex-wrap: wrap;
    @include font-serif-bold;
    font-size: 0.875rem;
    justify-content: flex-end;
    line-height: 2.286;
    max-width: 100ch;
    text-align: right;

    @media (max-width: $bp--phone) {
        font-size: 0.75rem;
        justify-content: center;
        line-height: 1.85;
        margin-top: 1rem;
    }
}

.site-footer__office-list-item {
    display: block;
    @include font-serif;
}

.site-footer__office-link {
    $color: $color--brand-gold;

    transition: $transition-text-color;
    color: $color;

    &:hover {
        color: $color--floral-white;
    }

    &::after {
        content: ". ";
        white-space: pre;
    }
}

.site-footer__column--left {
    flex: 1 25%;
    margin-right: 5rem;

    @media (max-width: $bp--phone) {
        margin-right: 0;
        text-align: center;
    }
}

.site-footer__link-list-item {
    margin-top: 1rem;

    @media (max-width: $bp--phone) {
        margin-top: 0.5rem;
    }
}

.site-footer__link-list {
    margin-top: 1.2rem;
}

.site-footer__container {
    background-color: $color--black;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 5% 6.4%;
    position: fixed;
    width: 100%; // IE 11: Make element full width
    @media (max-width: $bp--phone) {
        display: block;
        padding: 6.75% 9.6%;
    }
}

.site-footer__container-home {
    width: 90%;
    margin-left: 5%;
    padding: 4.05% 5.76%;

    @media (max-width: $bp--tablet) {
        width: 100%;
        margin-left: 0;
    }
}

.site-footer__disclaimer-right {
    float: right;
    color: $color--white;

    p {
        color: $color--white;
    }

    @include screen($bp--phone) {
        margin-top: 0.5rem;
        float: none;
    }
}
