.related-content{
    background-color: $color-gray-darkest;
    padding-left: $detail-padding-left;
    width: $detail-content-width;
    padding-right: $detail-padding-right;
    color: $color--floral-white;
    .related-tabs{
        overflow: hidden;
        margin-top: 2rem;
    }
    .tab-item{
        background-color: rgba(0,0,0,0.5);
        @include font-serif;
        font-size: rem(18px);
        margin: 0;
        padding: 15px;
        display: inline-block;
        float: left;
        cursor: pointer;
        font-weight: 600;
        color: $color--floral-white;
        transition: $transition-text-color;
        &.active,&:hover{
            color: $color--cornflower-blue;
        }
    }
    .tab-content{
        display: none;
        transition: all 0.2s ease-in-out;
        &.transition{
            display: block;
            visibility: hidden;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
        &.active{
            visibility: visible;
            opacity: 1;
            transition: all 0.2s ease-in-out 0.2s;
        }
    }
    .insight-list{
        padding: 0;
        .insight-item{
            padding: 15px 0 0 0;
        }
    }
    .rich-text{
        color:$color--white;
        p{
            color:$color--white;
        }
    }
    @include screen($bp--phone){
        padding: 0 $site-padding-mobile;
        width: calc(100% - #{$site-padding-mobile} * 2);
    };
}
