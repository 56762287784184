.accordion-capabilities-list {
    margin-left: 1rem;

    .accordion-capability {
        font-size: rem(20px);
        padding: rem(10px) 0;

        a {
            @include font-sans-serif;
            color: $color--black;
            text-decoration: underline;
            transition: color 0.3s ease-in-out;

            &:hover {
                text-decoration: none;
                color: $color--bistro-brown;
            }
        }
    }
}
