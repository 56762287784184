.stay-connected{
    padding: rem($section-spacing) $site-padding * 2;
    background-color: $color--cornflower-blue;
    color: $color--black;
    display: flex;

    &__header {
        @include font-serif-bold;
        font-size: rem(31.5px);
        line-height: 1.4;
        font-weight: bold;
    }

    .btn{
        @include filler-link( $background-color--initial: $color--half-baked, $background-color--hover: $color--white, $color---initial: $color--black, $color---hover: $color--black );
        border-color: $color--white;
        display: inline-block;
        margin-top: 10px;
        font-size: rem(16px);
        padding: 6px 12px;
        letter-spacing: 1.8;
        text-decoration: none;
    }
    .rich-text{
        +.rich-text{
            margin-top:$section-spacing;
        }
        p{
            color: $color--black;
            line-height: rem(24px);
            font-size: rem(16px);
            @include font-serif;
        }
    }
    .column{
        width: 48%;
        margin-right: 2%;
        min-height: 500px;
        +.column{
            margin-left: 2%;
            margin-right: 0;
        }
    }
    .twitter-feed{
        height: 100%;
    }
    @include screen($bp--phone){
        padding: rem($section-spacing) $site-padding-mobile * 2;
        display: block;
        .column{
            // width: calc(100% - 4 * #{$site-padding-mobile});
            width: 100%;

            +.column {
                margin: 0;
            }

        }
        .twitter-feed{
            height: auto;
            margin-top: $section-spacing;
        }
        .rich-text{
            p{
                font-size: 16px;
            }
        }
    }
}
