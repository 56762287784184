@use "sass:math";

.back-to-top-button {
	$size: rem(40px);

	text-transform: uppercase;
	position: fixed;
	right: rem(20px);
	bottom: rem(320px);
	color: $color--brand-gold;
	z-index: $z-index__back-to-top-button;
	transition: opacity .3s, visibility .01s .3s;
	visibility: hidden;
	opacity: 0;

    @include font-sans-serif-bold;

	&:before {
		content: '';
		display: block;
		width: $size;
		height: $size;
		margin-left: auto;
		background-color: $color--brand-gold;
		transition: background-color .3s;
        box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.3);
	}

	&:after {
		$arrow-size: math.div($size, 3);
		$border-style: 2px solid $color--white;

		content: '';
		display: inline-block;
		position: absolute;
		top: $size * 0.5;
		right: $size * 0.5;
		width: $arrow-size;
		height: $arrow-size;
		transform: translate3d(50%, -25%, 0) rotate(45deg);
		border-left: $border-style;
		border-top: $border-style;
	}

	&:hover {
		&:before {
			background-color: $color--dark-tan;
		}
	}

	&.show-button {
		visibility: visible;
		opacity: 1;
		transition: opacity .3s .01s, visibility .01s;
	}

    @include screen($bp--phone-small){
        bottom: rem(175px);
    }

    @include screen($bp--phone){
        display: none;
    }
}
