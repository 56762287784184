.attachments-list {
    $left-spacing: 1.5rem;

    .list-label {
        text-transform: uppercase;
        color: $color--dark-gray;
        letter-spacing: 1px;

        @include font-sans-serif-bold;
    }

    .attachments-list-item {
        padding-left: $left-spacing;
        margin: 1rem 0;
        position: relative;
    }

    .attachment-download-link {
        @include font-sans-serif-bold;
        color: $color--black;
        cursor: pointer;

        &:before {
            content: 't';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            font-family: $icomoon-font-family;
            color: $color--brand-gold;
        }

        &.attachment-pdf {
            &:before {
                content: $icon-pdf;
            }
        }

        &:hover {
            color: $color--dark-gray;
        }
    }
}
