.accordion-offices {
    .module-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .section-header {
        margin-bottom: 0;
        margin-right: 1rem;
    }

    .btn-expand-all {
        margin: .5rem 0;
        min-width: rem(175px);

        @include filler-link(
            $background-color--initial: $color--white,
            $color---initial: $color--black,
            $background-color--hover: $color--brand-gold,
            $color---hover: $color--black
        );

        @include font-sans-serif-bold;
    }

    .region-accordions {
        margin-top: 2rem;
    }

    .accordion {
        max-width: none;
    }
}
