.page-contact-us {
    $spacing: rem(72px);

    .contact-section {
        padding: $spacing 0;
        background-color: $color--floral-white;
    }

    .connect-module {
        padding: $spacing 0;
        background-color: $color--cornflower-blue;
    }

    .stay-connected {
        padding: 0;
        background-color: inherit;
    }

    .disclaimer-rich-text {
        display: inline;
        font-size: 1rem;
        p {
            display: inline;
        }
    }

    .disclaimer-checkbox {
        display: inline;
        height: 1.25rem;
        width: 1.25rem;
        vertical-align: middle;

        @include screen($bp--tablet) {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    .drop-down {
        border: none;
    }

    .form-submit {
        font-size: 1.25rem;
    }

    :invalid {
        box-shadow: none;
    }

    :-moz-submit-invalid {
        box-shadow: none;
    }

    :-moz-ui-invalid {
        box-shadow: none;
    }

    select{
        -webkit-appearance: menulist;
        -moz-appearance: menulist;
    }

    select::-ms-expand {
        display: block;
    }

    .disclaimer{
        padding-top:1rem;
    }
}
