.accordion{
    max-width: $max-width-chars;
    border-bottom: solid 1px $color--min-grey;
    $label-font-size: 28px;

    .accordion-label{
        font-size: rem($label-font-size);
        @include font-sans-serif-bold;
        line-height: rem(38px);
        color: $color--bistro-brown;
        position: relative;
        padding: 15px 20px 15px 0;
        cursor: pointer;
        transition: $default__anchor-transition;

        &:hover {
            color: $color--black;
        }
    }
    label{
        cursor: pointer;
    }
    .accordion-trigger{
        font-size: rem($label-font-size);
        @include font-sans-serif-bold;
        line-height: rem(38px);
        cursor: pointer;
    }
    .plus-icon{
        color: $color--cornflower-blue;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0;
        font-style: normal;
        &:after{
            content:'+';
            font-size: 2.25rem;
        }
    }
    &.open{
        .accordion-container{
            visibility: visible;
        }
        p:first-child{
            margin-top: rem(30px);
        }
        p:last-child{
            margin-bottom: rem(30px);
        }
        .plus-icon{
            &:after{
                content:'-';
                font-size: 2.25rem;
            }
        }
    }

    .office-tiles-grid {
        padding: rem(40px) 0;
    }

    .accordion-container{
        visibility: hidden;
        height: 0;
        overflow: hidden;
    }

}
