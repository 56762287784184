.cc-window {
    font-family: "PT-Sans";
    z-index: 100000;
    border-radius: 0 !important;
    font-size: 1rem;

    &.cc-invisible {
        display: none;
    }

    // Touch-screen devices
    &.cc-floating {
        .cc-close {
            padding: 0 0 5px 10px; // For fat fingers
        }

        .cc-message {
            padding-right: 2em;
            line-height: 1.25;
        }

        .cc-btn.cc-dismiss {
            padding: 0.2em 0.4em;
            min-width: 8em;
        }
    }

    // Non-touch-screen devices
    &:not(.cc-floating) {
        .cc-close {
            @include screen($bp--tablet, 'min') {
                position: static;
                margin-left: 0.4em;
            }
        }

        .cc-message {
            padding-right: 1.8em;
        }
    }

    .cc-close {
        font-size: 2em;
    }

    .cc-compliance {
        .cc-btn.cc-dismiss {
            border-radius: 0;
        }
    }

    @include screen($bp--phone) {
        display: block;

        .cc-compliance {
            margin-top: 1em;
        }
    }
}