// Vars
// TODO: remove these extra transition variables
@use "sass:math";

$hero--transition-duration-one: 2.5s;
$hero__building-images: -5;
$hero__building-image: 20;
$hero__building-image--left: 10000;
$hero__building-image--right: 10000;
$hero__building-images__image-overlay-gradient: 15;

// Block
.hero {
    box-sizing: border-box;
    height: 100vh;
    padding-top: 20vh;
    position: relative;
    z-index: $z-index__hero;

    @media (max-width: $bp--phone) {
        padding-top: 20vh;
    }

    + * > :first-child {
        $transition-duration: .75s;

        transition-delay: $hero--transition-duration-one * 0.5;
        transition-duration: $transition-duration;
        transition-property: margin-top;
        opacity: 0;
    }
}

// Element
.hero__poster {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
}
.hero__building-images {
    opacity: 0;
    transition: opacity $hero--transition-duration-one;
    transition-delay: math.div($hero--transition-duration-one, 3);
    height: 100%;
    width: 100%;
    // TODO: get this z-index out of here
    z-index: 1;
    display: none;

    @media (max-width: $bp--phone) {
        top: 5vh;
    }

    &::before {
        background-color: $color--white;
        content: "";
        top: 100%;
        height: 100vh;
        position: absolute;
        width: 100%;
        z-index: 10000;
    }
}

.hero__images-container {
    @include image-overlay-gradient($z-index: 1000);
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    overflow: hidden;
    z-index: -5;
    background: #3C3414;

    @include screen($bp--site-search-filters) {
        display: flex;
        justify-content: center;
    }
}

.hero__content-container {
    position: relative; // For z-index
    z-index: 1;

    opacity: 0;
    transition: opacity $hero--transition-duration-one;
}

.hero__background-image {
    opacity: 0;
    transition: opacity $hero--transition-duration-one;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    @include screen($bp--site-search-filters) {
        height: 100vh;
        width: auto;
        position: static;
    }
}

.hero__video-controls {
    position: absolute;
    right: 5%;
    bottom: 30%;
}

.hero__video-control--hide {
    display: none;
}

.hero__primary-heading {
    @include font-serif-bold;
    font-size: 4.5rem;
    letter-spacing: 0.07125rem;
    text-align: center;
    // transition: opacity $hero--transition-duration-two $hero--transition-duration-one;
    @media (max-width: $bp--phone) {
        font-size: 2.6rem;
    }

    @media (max-width: $bp--phone-small) {
        font-size: 2.4rem;
    }
}

.hero__building-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // will-change: transform;
    z-index: $hero__building-image;
}

.hero__cta {
    margin-top: 3.5rem;
    // opacity: 0;
    text-align: center;
    // transition-delay: $hero--transition-duration-one + $hero--transition-duration-two;
    // transition-duration: $hero--transition-duration-three;
    // transition-property: opacity;
}

.hero__cta-link {
    // @include hover-box-shadow;
    @include filler-link( $background-color--initial: $color--black, $background-color--hover: $color--floral-white, $color---initial: $color--floral-white, $color---hover: $color--black );
    background-color: $color--black;
    color: $color--white;
    display: inline-block;
    padding: 0.9375rem 1.4375rem;
    text-transform: uppercase;

    &::before {
        color: transparent;
        content: attr(data-text-content);
        display: block;
        font-weight: 600;
        height: 1px;
        margin-bottom: -1px;
        overflow: hidden;
        visibility: hidden;
    }
}

.hero__cta-gold-link {
    // @include hover-box-shadow;
    @include filler-link($background-color--initial: $color--black,
            $color---initial: $color--white,
            $background-color--hover: $color--brand-gold,
            $color---hover: $color--white );
    background-color: transparent;
    color: $color--brand-gold;
    display: inline-block;
    padding: 0.9375rem 1.4375rem;
    text-transform: uppercase;
    border: 2px solid #be9b39;
    &::before {
        color: transparent;
        content: attr(data-text-content);
        display: block;
        font-weight: 600;
        height: 1px;
        margin-bottom: -1px;
        overflow: hidden;
        visibility: hidden;
    }
}
.hero__building-image--center {
    position: absolute;
    left: 50%;
    top: 50vh;
    // top: 7.5vh;
    // margin-right: -50%;
    transform: translateX(-50%);
    // transform: translateX(-50%) scale(0.8);
    // transition: transform $hero--transition-duration-one;
}

.hero__building-image--left {
    left: 0;
    z-index: $hero__building-image--left;
}

.hero__building-image--right {
    right: 0;
    z-index: $hero__building-image--right;
}

.hero__building-image--right,
.hero__building-image--left {
    top: 56.5vh;
    // transform: scale(0.8);
    // transition: transform $hero--transition-duration-one;
    @media (max-width: $bp--tablet) {
        display: none;
    }
}

// Block Modifiers
.hero {
    &.hero--animate {
        // $hero-image-left-right-translate-x: 100%;
        // $hero-image-left-right-translate-y: -90vh;
        .hero__building-images,
        .hero__background-image,
        .hero__content-container {
            opacity: 1;
        }

        .hero__cta,
        .hero__primary-heading {
            // opacity: 1;
        }

        .hero__building-image--center {
            // transform: translateX(-50%) translateY(-75vh) scale(1);
        }

        .hero__building-image--left {
            // transform: scale(1) translateX($hero-image-left-right-translate-x) translateY($hero-image-left-right-translate-y);
        }

        .hero__building-image--right {
            // transform: scale(1) translateX(-$hero-image-left-right-translate-x) translateY($hero-image-left-right-translate-y);
        }

        + * > :first-child {
            opacity: 1;
            position: relative;
            z-index: 51; //TODO: do something with this for z-index.scss
            @include screen($bp--phone-small, 'min') {
                margin-top: -15%;
            }
        }

        @include screen($bp--phone-small) {
            &:after {
                $size: 2rem;

                content: '';
                display: inline-block;
                width: $size;
                height: $size;
                border: 5px solid $color--black;
                border-left: none;
                border-top: none;
                position: absolute;
                // bottom: 1rem;
                bottom: 30%;
                left: 50%;
                transform: rotate(45deg) translate3d(-50%, 0, 0);
            }
        }
    }
}



%hero__gold-button {    
    @include filler-link( $background-color--initial: transparent, $color---initial: $color--brand-gold, $background-color--hover: $color--brand-gold, $color---hover: $color--white );
    // margin: $vertical-spacing .5em 0;
    text-transform: uppercase;
    padding: 1em 2em;
    border: 2px solid $color--brand-gold;
    display: inline-block;
    color: $color--brand-gold;

    @include font-sans-serif-bold;

    @include screen($bp--tablet, 'min') {
        &:hover {
            color: $color--white;
        }
    }
}