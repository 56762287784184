// Var
$site-nav__background-color: $color--white;
$site-nav__before--bp-tablet: 0;
$site-nav__before: 40;
$site-nav__above-before: $site-nav__before + 10;
$site-nav__container--bp-phone: 5;
$site-nav__logo-container: -1;
$site-nav-primary-nav: -1;
$site-nav__search-field-container: 0;
$site-nav__search-form: 82;
$site-nav__search-overlay-button: 100;
$site-nav__search-overlay-button: 2000;
$site-nav__search-overlay-icon: 10000;
$site-nav__search-overlay-transition-duration: 0.5s;
$site-nav__search-overlay: 250;
$site-nav__search-submit-button-icon-height--mobile: 1rem;
$site-nav__search-submit-button-icon-height: 1.75rem;

// Block
.site-nav {
    $transition-duration: 0.65s;

    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0;
    padding: 1rem $site-padding; // Viewport unit instead of percentage: https://drafts.csswg.org/css-flexbox/#item-margins
    position: fixed;
    top: 0;
    transition: background-color $transition-duration, transform $transition-duration;
    width: 100%;
    z-index: $z-index__site-nav; // Above hero and main
    .site-logo {
        order: -1;
        position: relative;
        z-index: $site-nav__above-before;
    }



    .icon-logo {
        font-size: rem(40px);
    }

    @media (max-width: $bp--tablet) {
        padding: 0.75rem 6.3%;
        padding-left: 2.5%;
        padding-right: 2.5%;
    }
    // Search overlay
    &::before {
        background-color: rgba($color--brand-gold, 0.98);
        content: "";
        left: 0;
        min-height: 100vh;
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        transition: transform $site-nav__search-overlay-transition-duration;
        // will-change: transform;
        width: 100vw;
        z-index: $site-nav__before;

        @media (max-width: $bp--site-nav-break) {
            z-index: $site-nav__before--bp-tablet;
        }
    }
}

.site-nav--home {
    background: rgba($color--white,0.75);
}

// Element
.site-nav__search-submit-button-flex-container {
    align-items: center;
    display: flex;
}

.site-nav__logo {
    display: inline-block;
    vertical-align: middle;
    height: 39px;

    @media (max-width: $bp--site-nav-break) {
        height: 35.9px;
    }
}

.site-nav__logo-text {
    color: $color--black;
    @include font-serif;
    font-size: 1.875rem;
    margin-left: 1rem;

    @media (max-width: $bp--tablet) {
        display: none;
    }
}

.site-nav__secondary-list {
    display: flex;
    flex-grow: 1;

    @media (max-width: $bp--site-nav-break) {
        display: block;
    }
}

.site-nav__secondary {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    order: -1;

    @media (max-width: $bp--site-nav-break) {
        margin-top: 1rem;
        order: 0;
        width: 100%;
    }

    @media (max-width: $bp--site-nav-break) {
        display: block;
    }
}

.site-nav__nav-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    @media (max-width: $bp--site-nav-break) {
        box-sizing: border-box;
        left: 0;
        opacity: 0;
        padding: 5%;
        position: fixed;
        top: 3rem;
        transition: opacity 0.6s;
        visibility: hidden;
        width: 100%;
        z-index: $site-nav__container--bp-phone;
    }
}

.site-nav__search-form {
    font-size: 3rem;
    left: 50%;
    opacity: 0;
    position: fixed;
    top: 50vh;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 0.65s;
    visibility: hidden;
    width: 40rem;
    z-index: $site-nav__search-form;

    @media (max-width: $bp--site-nav-break) {
        color: $color--black;
        font-size: 1rem;
        margin-top: 1rem;
        position: static;
        transform: none;
        width: auto;
    }
}

.site-nav__search-field-label {
    align-items: flex-end;
    display: flex;
}

.site-nav__search-field {
    border-bottom: 1px solid;
    box-sizing: border-box;
    color: $color--black;
    display: inline-block;
    flex: 1;
    @include font-serif;
    font-size: 2.5rem;
    margin-left: 1rem;
    padding-right: $site-nav__search-submit-button-icon-height * 1.5;
    width: 100%;

    @media (max-width: $bp--site-nav-break) {
        color: $color--black;
        @include font-sans-serif;
        font-size: 1.125rem;
        padding-right: $site-nav__search-submit-button-icon-height--mobile * 1.5;
    }
}

.site-nav__search-field-label-text {
    display: inline-block;
    @include font-serif;
    line-height: 0.85;

    @media (max-width: $bp--site-nav-break) {
        @include font-sans-serif;
        font-size: 1.125rem;
        letter-spacing: 1px;
        line-height: 0.9;
        text-transform: uppercase;
    }
}

.site-nav__secondary-item {
    &:not(:first-child) {
        margin-left: 1rem;
    }
}

.site-nav__secondary-link {
    $color: $color--black;
    font-size: 16px;
    @include underline-link($color);
    color: $color;
    letter-spacing: 0.044375rem;
    transition: $transition-text-color;

    @media (max-width: $bp--site-nav-break) {
        $color: $color--black;

        @include underline-link($color);
        color: $color;
        font-size: 1.75rem;
        line-height: 1.5;

        &:not(:last-of-type) {
            margin-bottom: 0.75rem;
        }
    }
}

.site-nav__search-button {
    $color: $color--black;

    align-items: center;
    color: $color;
    display: flex;
    margin-left: 0;
    justify-content: flex-end;
    margin-right: .5rem;

    &:hover {
        .icon--loupe {
            transform: scale(1.25);
        }
    }

    .icon--loupe {
        $height: 1rem;

        display: block;
        fill: $color;
        height: $height;
        transition: transform 0.4s;
        width: $height;
    }
}

.site-nav__search-button-text {
    color: $color--black;
    display: inline-block;
    font-size: 1rem;
    letter-spacing: .044375rem;
    line-height: 100%;
    text-transform: capitalize;
    position: relative;
    top: 1px;
}

.site-nav__search-submit-button-icon {
    margin-left: 0.3rem;
    margin-right: 0.4rem;
}

.site-nav__search-overlay {
    background-color: rgba($color--brand-gold, 0.95);
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition: transform 0.75s;
    visibility: hidden;
    width: 100vw;
    z-index: $site-nav__search-overlay; // Above
}

.site-nav__search-overlay-button {
    cursor: pointer;
    height: 3rem;
    position: absolute;
    visibility: hidden;
    z-index: $site-nav__search-overlay-button;

    &::before {
        content: "";
        display: inline-block;
        height: 5rem;
        left: -50%;
        position: absolute;
        top: -50%;
        width: 5rem;
        z-index: $site-nav__search-overlay-button;
    }

    @media (max-width: $bp--site-nav-break) {
        opacity: 1;
        position: static;
        visibility: visible;
    }
}

.site-nav__search-overlay-icon {
    $background-color: $color--black;
    $spacing: 8px;
    $spacing-mobile: 8px;

    background: $background-color;
    height: 2px;
    opacity: 0;
    position: relative;
    transition: opacity $site-nav__search-overlay-transition-duration;
    width: 32px;
    z-index: $site-nav__search-overlay-icon;

    @media (max-width: $bp--site-nav-break) {
        opacity: 1;
        transition: background-color $site-nav__search-overlay-transition-duration;
        width: 16px;
    }

    &::before,
    &::after {
        background: $background-color;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        right: 0;
        transform-origin: 100% 50%;
        width: 40px;

        @media (max-width: $bp--site-nav-break) {
            transition: transform $site-nav__search-overlay-transition-duration, background-color $site-nav__search-overlay-transition-duration;
            width: 24px;
        }
    }

    &::before {
        top: -$spacing;

        @media (max-width: $bp--site-nav-break) {
            top: -$spacing-mobile;
        }
    }

    &::after {
        top: $spacing;

        @media (max-width: $bp--site-nav-break) {
            top: $spacing-mobile;
        }
    }
}

.site-nav__search-submit-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
        .icon {
            transform: scale(1.2);
        }
    }

    .icon {
        $height: 1.75rem;

        fill: $color--black;
        height: $site-nav__search-submit-button-icon-height;
        transition: transform 0.4s;
        width: $site-nav__search-submit-button-icon-height;

        @media (max-width: $bp--site-nav-break) {
            $height: 0.75rem;

            fill: $color--black;
            height: $site-nav__search-submit-button-icon-height--mobile;
            width: $site-nav__search-submit-button-icon-height--mobile;
        }
    }
}

.site-nav__search-field-container {
    display: flex;
    position: relative;
    z-index: $site-nav__search-field-container;

    @media (max-width: $bp--site-nav-break) {
        display: inline-block;
    }

    > * {
        flex: 1;
    }
}

.site-nav__primary {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $bp--site-nav-break) {
        display: block;
    }
}

.site-nav__primary-item {
    $margin-horizontal: 1rem;

    display: block;

    &:not(:first-child) {
        margin-left: 1.5625rem;
    }

    @media (max-width: $bp--site-nav-break) {
        margin-left: 0;

        &:not(:first-child) {
            margin-left: 0;
        }

        margin-right: $margin-horizontal;
    }
}

.site-nav__primary-link {
    $color: $color--black;
    $color---mobile: $color--black;

    @include underline-link($color);
    color: $color;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
    transition: $transition-text-color;

    @media (max-width: $bp--site-nav-break) {
        @include underline-link($color: $color---mobile);
        font-size: 2rem;
        line-height: 1.25;

        &.site-nav__primary-link {
            color: $color--black;

            &.is-active {
                color: $color--black;
            }
        }
    }

    &:hover {
        @media (max-width: $bp--site-nav-break) {
            color: $color---mobile;
        }
    }
}

// Block Modifiers
.site-nav {
    &.site-nav--hidden {
        transform: translateY(-100%);
        // TODO: make this 0.4s into avairable
        transition: transform 0.4s;
    }

    &.white-text {
        $color: $color--white;

        .site-nav__secondary-link {
            @include underline-link($color);
            color: $color;

            @include screen($bp--site-nav-break) {
                color: $color--black;

                &:hover,
                &.is-active {
                    color: $color--black;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .site-nav__search-button {
            color: $color;

            .icon--loupe {
                fill: $color;
            }
        }

        .site-nav__search-button-text {
            color: $color;
        }

        .site-nav__primary-link {
            color: $color;

            &:after {
                background-color: $color;
            }

            @media (max-width: $bp--site-nav-break) {
                color: $color--black;

                &.is-active,
                &:hover {
                    color: $color--black;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .icon-logo {
            color: $color--white;
        }

        .site-nav__search-overlay-icon {
            background-color: $color--white;

            &::before {
                background: $color--white;
            }

            &::after {
                background: $color--white;
            }
        }
    }

    &.site-nav--opaque, &.white-background {
        background-color: rgba($site-nav__background-color, 1);
        box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.05);

        .site-nav__secondary-link {
            $color: $color--black;
            @include underline-link($color);
            color: $color;

            @include screen($bp--site-nav-break) {
                color: $color--white;

                &:hover,
                &.is-active {
                    color: $color--black;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .site-nav__search-button {
            $color: $color--black;
            color: $color;

            .icon--loupe {
                fill: $color;
            }
        }

        .site-nav__search-button-text {
            color: $color--black;
        }

        .site-nav__primary-link {
            color: $color--black;

            @include screen($bp--site-nav-break) {
                color: $color--black;

                &.is-active,
                &:hover {
                    color: $color--black;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .icon-logo {
            color: $color--black;
        }

        .site-nav__search-overlay-icon {
            @include screen($bp--site-nav-break) {
                background-color: $color--black;

                &:before {
                    background-color: $color--black;
                }

                &:after {
                    background-color: $color--black;
                }
            }
        }
    }

    &.white-background {
        $color: $color--black;

        .site-nav__secondary-link {
            @include underline-link($color);
            color: $color;

            @include screen($bp--site-nav-break) {
                color: $color--black;

                &:hover,
                &.is-active {
                    color: $color--black;

                    &:after {
                        content: none;
                    }
                }
            }
        }

        .site-nav__search-button {
            color: $color;

            .icon--loupe {
                fill: $color;
            }
        }

        .site-nav__search-button-text {
            color: $color;
        }
    }

    &.site-nav--white-icon {
        .site-nav__search-overlay-icon {
            &,
            &:after,
            &:before {
                background-color: $color--white;
            }
        }
    }
    // Search overlay is active
    &.site-nav--search-overlay-active {
        &::before {
            transform: translateY(0);
        }

        .site-logo {
            @include screen($bp--site-nav-break) {
                visibility: hidden;
            }
        }

        .site-nav-primary-item {
            @media (max-width: $bp--site-nav-break) {
                margin-bottom: 0.75rem;
                margin-left: 0;
            }
        }

        .site-nav__secondary-item {
            @media (max-width: $bp--site-nav-break) {
                margin-left: 0;
            }
        }

        .site-nav__search-form {
            opacity: 1;
            visibility: visible;

            @media (max-width: $bp--site-nav-break) {
                margin-right: auto;
            }
        }

        .site-nav__search-overlay-button {
            position: absolute;
            right: 15vh;
            top: 3vh;
            visibility: visible;

            @media (max-width: $bp--site-nav-break) {
                position: static;
            }
        }

        .site-nav__logo-container {
            position: relative;
            z-index: $site-nav__logo-container;
        }

        .site-nav-primary {
            position: relative;
            visibility: hidden;
            z-index: $site-nav-primary-nav;

            @media (max-width: $bp--site-nav-break) {
                visibility: visible;
            }
        }

        .site-nav__nav-container {
            @media (max-width: $bp--site-nav-break) {
                opacity: 1;
                visibility: visible;
            }
        }

        .site-nav__search-button {
            visibility: hidden;
        }

        .site-nav__search-overlay-icon {
            $rotation: 50deg;

            background-color: transparent;
            opacity: 1;
            z-index: 0; // TODO: get rid of this z-index; it does not go well with z-index.scss
            &::before {
                transform: rotate(-$rotation) translateX(11.4px) translateY(-9.3px);

                @media (max-width: $bp--site-nav-break) {
                    transform: rotate(-$rotation) translateX(2px) translateY(-1.3px);
                    visibility: visible;
                }
            }

            &::after {
                transform: rotate($rotation);
                visibility: visible;
            }

            @media(max-width: $bp--site-nav-break){
                visibility: hidden;
            }
        }
    }
}