.page-insights-detail {
    $spacing: rem(72px);

    .article-background-info {
        padding-top: $spacing;
        background-color: $color--white;
    }

    .article-content {
        padding: $spacing 0;
        background-color: $color--white;
    }

    .related-articles {
        background-color: $color-gray-darkest;
        padding: $spacing $site-padding;

        .section-header {
            color: $color--white;
        }
    }

    .insight-list {
        padding: 0;
        background-color: inherit;
    }

    .connect-module {
        padding: $spacing 0;
        background-color: $color--cornflower-blue;
    }

    .stay-connected {
        padding: 0;
        background-color: inherit;
    }
    .related-links{
        .inline-list{
            flex-wrap: nowrap;
        }
    }
}
