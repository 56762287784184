@use "sass:math";

.interior-infographic{
    padding: 0 $site-padding $section-spacing;
    background-color: $color--floral-white;
    position: relative;

    &-tab-control {
        position: absolute;
        top: 46%;
        right: -5%;

        &-play {
            display: none;
        }
    }

    .infographic-content{
        width: 100%;
        background-color: $color--cornflower-blue;
        color: $color--floral-white;
        box-shadow: 0 5px 15px 0 rgba($color--gray-medium, .4);
        position: relative;
    }

    .infographic-content {
        display: flex;
        flex-wrap: wrap;
    }

    .trigger{
        width: math.div(100, 3) * 1%;
        color: $color--white;
        text-align: center;
        position: relative;
        height: rem(184px);
        background-color: $color-gray-darkest;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        padding: .5rem;
        box-sizing: border-box;

        &:last-child{
            border-right: none;
        }
        &.active, &:hover{
            background-color: $color--cornflower-blue;
            color: $color--black;
            z-index: 100;
        }
        @include screen($bp--phone){
                border-bottom: 1px solid $color-gray-darkest;
        }
    }
    .trigger-content{
        @extend %align-center;
        position: absolute;
        font-weight: bold;
    }
    .info{
        display: block;
        font-size: rem(56px);
    }
    .tagline{
        font-size: rem(20px);
        line-height: 1.125;
        font-weight:400;
    }
    .infographic-text{
        order: 1;
        color: $color--black;
        h3 {
            @include font-sans-serif;
            font-size: rem(24px);
        }
        p{
            color: $color--black;
            max-width: 1000ch;
            font-size: rem(24px);
            line-height: 1.5;
        }

        +.trigger{
            border-left: 1px solid $color-gray-darkest;
            @include screen($bp--phone){
                border-left: 0;
            }

        }
    }
    .text-container{
        padding: rem(36px) rem(96px);
        font-size: rem(18px);
        @include font-sans-serif;
        line-height: rem(24px);
        position: relative;

        display: none;
        //&.inactive {
        //    display: none;
        //}
        p{
            font-size: rem(20px);
        }

        &.active{
            display: block;
        }
    }

    @include screen($bp--phone){
        padding: 0;
        .infographic-content {
            display: block;
        }

        .trigger {
            width: 100%;
            background-color: $color--cornflower-blue;
            z-index: 1;
            &.open{

            &:after {
                $size: rem(32px);

                content: '';
                display: inline-block;
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                border: $size solid transparent;
                border-top: $size solid $color--cornflower-blue;
            }
            }
        }

        .text-container {
            padding: 3rem 2rem;
            display: none;
            background-color: $color-gray-darkest;
            &.active{
                display: none;
            }
            &.open{
                display: block !important;
            }
        }

        .infographic-text,
        .infographic-text p {
            color: $color--white;
        }
    }
}
