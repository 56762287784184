﻿.email-disclaimer {
    .content-width-wrapper{
        text-align: center;
        max-width: 100%;
        width: 100%;
        height: 540px;
        .logo{
            width:50%;
            margin: 0 auto;
        }
        .rich-text{
            padding: 1rem;
        }
        .highlight-link{
            margin: 1rem;
        }
    }
}
