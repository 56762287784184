// Var
$home-infographic-one__button-color: $color-gray-darkest;
$home-infographic-one__button-color-transition-duration: 0.4s;
$home-infographic-one__button-color--active: $color--cornflower-blue;
$home-infographic-one__carousel-button-list__before: -1;
$home-infographic-two__background-image: -1;
$home-infographic-two__button-outer-text-arrow: -1;

// Block
.home-infographic-two {
    padding: 10.4% 3.8%;
    position: relative;
    z-index: $z-index__home-infographic-two;

    @media (max-width: $bp--tablet) {
        padding: 10% 3%;
    }
}

// Element
.home-infographic-two__background-image {
    @include image-overlay-gradient($position: top);
    background-size: cover;
    height: 120%;
    left: 0;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    //transform: translateY(-10%);
    position: absolute;
    bottom: 0;
    width: 100vw;
    opacity: 0.5;
    z-index: $home-infographic-two__background-image;

    @media (max-width: $bp--tablet) {
        background-size: cover;
        bottom: 0;
        height: 100%;
        margin-top: 0;
        top: auto;
        transform: translateY(0);
    }

    &::before {
        background-color: rgba($color--white, 0.7);
        bottom: 0;
        content: "";
        height: 150%;
        left: 0;
        position: absolute;
        width: 100%;
    }
}

.home-infographic-two__cta {
    @include filler-link($color---initial: $color--black, $color---hover: $color--black);
    margin-top: 3.1rem;
}

.home-infographic-two__button-list {
    display: flex;
    flex-direction: column;
    float: right;

    @media (max-width: $bp--tablet) {
        align-items: baseline;
        flex-direction: row;
        flex-wrap: wrap;
        float: none;
        margin-top: 3rem;
        order: -1;
        position: static;
    }

    > * {
        @media (max-width: $bp--tablet) {
            flex: 1;
        }
    }
}

.home-infographic-two__button {
    align-items: stretch;
    flex-grow: 1;
    margin-left: auto;
    position: relative;

    @media (max-width: $bp--tablet) {
        display: flex;
        flex-direction: column;
    }
}

.home-infographic-two__button-outer-text-flex-container {
    @media (max-width: $bp--tablet) {
        box-sizing: border-box;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-left: 0;
    }
}

.home-infographic-two__cta-container {
    @media (max-width: $bp--tablet) {
        text-align: center;
    }
}

.home-infographic-two__content-container {
    @media (max-width: $bp--tablet) {
        text-align: center;
    }
}

.home-infographic-two__button-item {
    $border: 1px solid $color--dark-gray;

    display: flex;
    transition: border-color $home-infographic-one__button-color-transition-duration;

    &:not(:last-child) {
        border-bottom: $border;

        @media (max-width: $bp--tablet) {
            border-bottom-width: 0;

            .home-infographic-two__button-inner-text {
                border-right: $border;
            }
        }
    }

    &.home-infographic-two__button-item:hover,
    &.home-infographic-two__button-item--active {
        &:not(:last-child) {
            border-bottom-color: $home-infographic-one__button-color--active;
        }

        .home-infographic-two__button-inner-text {
            background-color: $home-infographic-one__button-color--active;
            color: black;
            @media (max-width: $bp--tablet) {
                border-right-color: $home-infographic-one__button-color--active;
            }
        }

        .home-infographic-two__button-outer-text-arrow {
            &::after {
                border-right-color: $home-infographic-one__button-color--active;
                transform: rotate(0);
            }
        }
    }

    > * {
        @media (max-width: $bp--tablet) {
            flex: 1;
            flex-basis: 100%;
        }
    }
}

.home-infographic-two__button-inner-text {
    background-color: $home-infographic-one__button-color;
    color: $color--floral-white;
    display: block;
    font-size: 4vw;
    font-weight: 700;
    order: -1;
    padding: 2.2vw 1.5vw;
    position: relative; // Above border triangle in stacking context
    transition: background-color $home-infographic-one__button-color-transition-duration, border-color $home-infographic-one__button-color-transition-duration;

    @media (max-width: $bp--tablet) {
        box-sizing: border-box;
        flex-grow: 1;
        font-size: 3rem;
        min-width: 0;
        padding: 4.4vw 3vw;
        width: 100%;
    }
}

.home-infographic-two__button-outer-text-container {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 2rem;
    text-align: right;
    width: 100%;

    @media (max-width: $bp--tablet) {
        justify-content: center;
        position: static;
        transform: translateY(0);
    }
}

.test {
    align-items: center;
    display: flex;

    @media (max-width: $bp--tablet) {
        justify-content: center;
    }
}

.home-infographic-two__button-outer-text-arrow {
    display: inline-block;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);

    &::after {
        $border-width: 1.5vw;

        border-bottom: $border-width solid transparent;
        border-right: $border-width solid $home-infographic-one__button-color;
        border-top: $border-width solid transparent;
        content: "";
        display: inline-block;
        height: 0;
        margin-left: 1rem;
        transform: rotate(90deg);
        transform-origin: 100% 100%;
        transition: border-right-color $home-infographic-one__button-color-transition-duration, transform $home-infographic-one__button-color-transition-duration;
        width: 0;
        z-index: $home-infographic-two__button-outer-text-arrow;

        @media (max-width: $bp--tablet) {
            display: none;
        }
    }
}

.home-infographic-two__content-container {
    min-height: 27vw;

    @media (max-width: $bp--tablet) {
        min-height: 100%;
    }
}

.home-infographic-two__button-outer-text {
    color: $home-infographic-one__button-color;
    display: inline-flex;
    font-size: 1.7vw;
    font-weight: 700;
    letter-spacing: 1px;
    position: relative;
    text-transform: uppercase;
    transition: color $home-infographic-one__button-color-transition-duration;

    @media (max-width: $bp--tablet) {
        font-size: 0.9rem;
        font-weight: 700;
        justify-content: center;
        margin-top: 0.75rem;
        text-align: center;
    }

    @media (max-width: $bp--phone) {
        letter-spacing: 1px;
    }
}

.home-infographic-two__description {
    color: $color--black;
    margin-top: 1.8rem;
    line-height: 1.4;
    font-size: 1.25rem;
    max-width: 60ch;

    @media (max-width: $bp--tablet) {
        margin-left: auto;
        margin-right: auto;
    }
}

.home-infographic-two__description,
.home-infographic-two__primary-heading {
    width: 60%;

    @media (max-width: $bp--tablet) {
        width: 100%;
    }
}

.home-infographic-two__primary-heading {
    color: $color--black;
    display: inline-block;
    font-size: 2rem;
    float: left;
    @media (max-width: $bp--tablet) {
        float: none;
        }
}

.locations-area {
    width: 60%;
    margin-top: 1.8rem;
    float: left;
    padding-bottom: 2rem;

    @include screen($bp--tablet) {
        float: none;
        padding-bottom: 0;
        margin: auto;
        display: none;
    }
}

.locations-area-mobile {
    width: 60%;
    max-width: 60ch;
    margin-top: 1.8rem;
    float: left;
    padding-bottom: 2rem;
    display: none;

    @include screen($bp--tablet) {
        float: none;
        padding-bottom: 0;
        margin: auto;
        display: block;
    }
}
