%clearfix {
    &::after {
        clear: both;
    }

    &::before,
    &::after {
        content: " ";
        display: table;
    }
}

%visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
%vertical-align-center {
	top:50%;
	transform: translateY(-50%);
}

%align-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}