@use "sass:math";

.filters-content {
    $color: $color-gray-darkest;
    background-color: $color--floral-white;
    color: $color;
    max-height: 0;
    transition: max-height 0.3s;

    &.active {
        max-height: 300px;
    }

    .filter-content {
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        transition: all 0.4s ease-in-out;
        position: relative;

        &.active {
            padding: rem(35px) $site-padding * 2;
            max-height: none;
            visibility: visible;
            opacity: 1;

            @include screen($bp--md) {
                padding: rem(35px) $site-padding;
            }
        }

        &.two-sections {
            flex-flow: 2;

            .section {
                + .section {
                    margin-top: 2rem;
                }
            }

            .input-field {
                width: math.div(100, 3) * 1% - 0.5%;
            }
        }
    }

    .section {
        .filter-section-container {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .filter-section-select-container {
                order: 1;
                flex: 0 0 auto;
                width: 100%;

                .filter-section-select,
                .filter-section-input {
                    color: $color--black;
                    border: 1px solid $color--brand-gold;
                    padding: 5px;
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            .filter-section-applied-container {
                order: 2;
                flex: 0 1 auto;
                margin: 0 0 0 5%;
                display: flex;
                flex-wrap: wrap;

                @include screen($bp--md) {
                    margin: 1% 0 0 0;
                }

                .filter-section-applied {
                    $animation: 0.2s ease-in-out;
                    font-size: rem(20px);
                    letter-spacing: 0.3px;
                    margin-right: 16px;
                    cursor: pointer;
                    transition: transform $animation, color $animation;
                    display: inline-block;
                    color: $color;

                    &:hover {
                        transform: scale(1.05);
                        transform-origin: center;
                        color: $color--brand-gold;
                    }

                    &:after {
                        content: 'x';
                        margin-left: 8px;
                    }
                }
            }

            .filter-section-typeahead-container {
                width: 100%;
                position: relative;

                .filter-section-input {
                    color: $color--brand-gold;
                    border: 1px solid $color--brand-gold;
                    padding: 5px;
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }
    }

    .filter-heading {
        display: block;
        text-transform: uppercase;
        @include font-sans-serif-bold;
        margin-bottom: rem(18px);
    }

    .input-field {
        display: inline-block;
        width: math.div(100, 6) * 1% - 0.5%;
        margin-bottom: rem(12px);
        text-align: top;
    }

    .date-input {
        display: inline-flex;
        align-items: center;
        padding-right: 1em;
        margin: .5em 0;
    }

    .datepicker-label {
        margin-right: .5em;
    }

    .search-filters-datepicker,
    .search-filters-datepicker--ng {
        background-color: $color--white;
        border: 1px solid $color--brand-gold;
        padding: .5em;

        @include font-sans-serif;
    }

    .input-flex {
        display: flex;
        flex-flow: row;
        columns: 2;
    }

    input[type=checkbox] {
        margin-right: .5rem;
        position: relative;
        top: 2px;
        vertical-align: middle;
    }

    .checkbox-label {
        width: 85%;
    }

    .filter-controls {
        display: flex;
        align-items: center;
        margin-top: 2rem;
    }

    .close {
        color: $color--brand-gold;
        display: inline-block;
        cursor: pointer;
        font-size: rem(30px);
        position: absolute;
        top: 2rem;
        right: $site-padding;
    }

    .apply {
        display: inline-block;
        margin-right: 1.5rem;
        @include filler-link($color---initial: $color--brand-gold, $color---hover: $color--white);
    }

    .clear-all {
        @include filler-link( $background-color--initial: $color-gray-darkest, $color---initial: $color--floral-white, $background-color--hover: $color--floral-white, $color---hover: $color-gray-darkest );
        display: inline-block;
        padding: rem(3px) rem(6px);
        font-weight: bold;
        font-size: rem(20px);
        text-transform: none;
    }

    @include screen($bp--phone) {
        $margin-top-mobile: 20px;
        $number-of-columns: 2;

        .filter-content {
            display: block;
            position: relative;

            .section {
                display: none;
            }

            .filter-controls {
                display: none;
            }

            &.active {
                max-height: 20rem;

                .filter-controls {
                    width: 100%;
                    position: static;
                    margin-top: $margin-top-mobile;
                    display: flex;
                    align-items: center;
                }

                .section {
                    display: block;
                    overflow: hidden;
                    width: 100%;
                }

                &.two-sections {
                    .section {
                        width: 100%;

                        + .section {
                            margin-top: $margin-top-mobile;
                        }
                    }

                    .input-field {
                        float: left;
                        width: math.div(100%, $number-of-columns);
                    }
                }
            }

            .input-field {
                float: left;
                width: math.div(100%, $number-of-columns);
            }
        }
    }

    ;
}
