.highlight-background-image {
    $spacing: 1.5rem;

    padding: rem(100px);
    position: relative;

    .background-image-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
    }

    .full-width-wrapper {
        display: flex;
    }

    .highlight-content {
        max-width: rem(550px);
        position: relative;
    }

    .highlight-meta {
        @include font-sans-serif-bold;
    }

    .highlight-date {
        color: $color--white;
    }

    .highlight-location {
        color: $color--min-grey;
        text-transform: uppercase;
        margin-left: 1rem;
    }

    .highlight-title {
        margin-top: $spacing;
        font-size: rem(32px);
        color: $color--white;
        letter-spacing: 2px;
        line-height: 1.25;

        @include font-serif-bold;
    }

    .highlight-link {
        margin-top: $spacing;
        border: 2px solid $color--brand-gold;

        @include filler-link(
            $background-color--initial: transparent,
            $color---initial: $color--brand-gold,
            $background-color--hover: $color--brand-gold,
            $color---hover: $color--white
        );

        @include font-sans-serif-bold;
    }

    &.content-right {
        .full-width-wrapper {
            justify-content: flex-end;
        }
    }

    @include screen($bp--phone){
        padding: rem(50px);

        .highlight-title {
            font-size: rem(28px);
        }
    }
}
