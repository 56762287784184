.page-events-landing {
    .insight-list {
        .no-insight-container {
            padding-top: 0 !important;
        }
    }

    .highlights-section {
        .view-more {
            width: 100%;
            text-align: center;
            color: $color--half-baked;
            padding: rem($section-spacing) 0;
            font-size: rem(20px);
            cursor: pointer;
            transition: $transition-text-color;

            background-color: $color-gray-darkest;
            border-bottom: solid 1px $color--gondola;;

            &:hover {
                color: $color--floral-white;
            }
        }
    }
}
