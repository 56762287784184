﻿.pdf-body {
    font-size: 10pt;
    line-height: normal;
    p, .rich-text p {
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1.5;
    }
    a {
        color: #5C5C5C !important;
    }
    h3 {
        font-size: 12pt;
        font-weight: bold;
        margin-bottom: 12px;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: Georgia, 'Times New Roman', Times, serif !important;
        letter-spacing: 0 !important;
        color: #4A4A4A !important;
    }
    .inline-list {
        max-width: none;
        .list-section {
            margin-bottom: 0;
            width: 100%;
        }
        .list-item-link {
            font-size: inherit;
        }
    }
    .rich-text {
        font-size: inherit;
        font-family: Georgia, 'Times New Roman', Times, serif;
        line-height: inherit;
        ul {
            font-size: inherit;
            & > li:before {
                line-height: 0.5;
                font-weight: 700;
            }
            ul {
                margin-left: 1em !important;
            }
        }
        li {
            line-height: 1.4;
            margin-bottom: 12px;
            padding-left: 1.5em;
            li:first-child {
                margin-top: 15px;
            }
        }
    }
    .section-header{
        font-size: 14pt;
        margin-bottom: 12px;
    }
    .bio-hero{
        @extend %clearfix;
        display: block;
        color: #5C5C5C;
        background-color: $color--white;
        margin: 0;
        padding: 0;
        width: 6.5in;
        padding-left: 1in;
        padding-right: 1in;
        .name {
            font-size: 18pt;
            padding-bottom: 0;
            margin-top: 30px;
            margin-bottom: 20px;
        }
        .main-title, .email {
            font-size: inherit;
            font-family: Georgia, 'Times New Roman', Times, serif;
            margin: 0;
        }
        .main-title {
            font-size: inherit;
            &:before {
                content: none;
            }
        }
        .bio-content{
            display: block;
            color: #5C5C5C !important;
            float: left;
            width: 3.75in;
            padding: 0;
        }
        .image-container{
            margin-left: auto;
            margin-right: auto;
            width: auto;
            display: block;
            float: left;
            padding: 0;
            img {
                display: block;
                height: auto;
                max-width: 2.75in;
            }
        }
        .offices-container {
            @extend %clearfix;
            width: 100%;
            margin-top: 20px;
            .offices {
                margin-bottom: 0px;
                display: block;
                .office-item {
                    font-family: Georgia, 'Times New Roman', Times, serif;
                    margin: 0 0 10px 0;
                    padding: 0;
                    font-size: inherit;
                    line-height: 1.5;
                    display: block;
                    width: 50%;
                    float: left;
                }
            }
        }
    }
    #profile {
        ul {
            @extend %clearfix;
            li {
                font-size: inherit;
                margin-bottom: 5px;
                width: 45%;
                float: left;
            }
        }
    }
    .main-content {
        padding: 0;
        width: 6.5in;
        margin-left: 0;
        margin-bottom: 0;
        padding-top: 20px;
        padding-left: 1in;
        padding-right: 1in;
        color: #5C5C5C !important;
    }
    .page-bio-detail{
        .disclaimer {
            margin-bottom: 12px;
        }
        .related-content{
            padding-left: 0;
            padding-top: 20px;
            background-color: $color--white;
            width: 100%;
            .related-section {
                margin-top: 15px;
            }
            .rich-text, .rich-text p {
                color: #4a4a4a;
            }
            .related-tabs{
                display: none;
            }
            .insight-container{
                background-color: $color--white !important;
            }
            .insight-item{
                background-color: $color--white;
                padding: 20px 0;
                border-width: 2px;
                border-color: #4A4A4A;
                &:first-child {
                    padding-top: 0px;
                }
                .insight-content{
                    margin: 0;
                    .top {
                        margin-bottom: 8px; 
                    }
                    .date, .insight-type {
                        font-family: Georgia, 'Times New Roman', Times, serif;
                        font-size: inherit;
                        color: #5C5C5C;
                        font-weight: normal;
                    }
                    .title-section{
                        color: #4A4A4A;
                        display: block;
                        margin-top: 0;
                        .title {
                            font-size: 12pt;
                            color: inherit;
                            letter-spacing: 0;
                            font-family: Georgia, 'Times New Roman', Times, serif;
                        }
                    }
                    .time-to-read {
                        display: none;
                    }
                }
            }
            .tab-content{
                display: initial;
            }
        }
    }
    .site-nav{
        background-color:$color--white;
        padding-left: 0;
    }
    .rich-text{
        section {
            + section {
                padding-top: 20px;
                &.after-services {
                    padding-top: 0px;
                }
            }
            &:first-child {
                padding-top: 0;
            }        
            &:last-child {
                padding-bottom: 0;
            }
        }
        .two-col {
            display: inherit;
            .column{
                width:inherit;
            }
        }
    }
}
