.article-content {

    .article-content-grid {
        display: flex;
    }

    .content-container {
        padding-right: 15%;
        width: 58%;
    }

    .rich-text {
        p {
            color: $color-gray-darkest;
        }
    }

    .share-container {
        box-sizing: border-box;
        width: 27%;
        padding-right: 50px;
        text-align: right;
    }

    .article-share-tools {
        padding-top: .5rem;
    }

    .attachments-list {
        margin-top: 3rem;
    }

    @include screen($bp--phone){
        .article-content-grid {
            flex-wrap: wrap;
        }

        .content-container {
            box-sizing: border-box;
            width: 100%;
            padding: 0 1rem;
            order: 1;
        }

        .share-container {
            width: 100%;
            order: 2;
            justify-content: center;
            padding: 0;
            text-align: left;
            margin-top: 2rem;

            .scrollmagic-pin-spacer {
                min-height: 0 !important;
                padding: 0 !important;
                text-align: center;
            }

            .article-share-tools {
                position: static !important;
                align-items: center;
                flex-direction: column;
                padding: 0 1rem;
                position: relative;
            }

            .share-tool-links {
                $spacing: 1rem;

                display: flex;
                margin: 0.5rem 0;
                padding-left: $spacing;
                border: none;
                border-left: 1px solid $color-gray-darkest;
                position: relative;
            }

            .share-tool-link-container {
                &:not(:last-child){
                    margin-right: 1rem;
                }
            }

            .share-tool-link {
                margin-top: 0;
            }

            .share-tool-label {
                display: inline-block;
                padding-right: 1rem;
            }

            .alert-subscribe-link {
                text-align: center;
            }

            .share-tool-section {
                display: inline-flex;
                text-align: left;
            }
        }

        .rich-text {
            word-wrap: break-word;
        }

    }
}
