.office-tile {
    $speed: .3s;

    background-color: $color--floral-white;
    display: flex;
    flex-direction: column;
    border-bottom: 5px solid transparent;
    box-shadow: 0 2px 5px 0 rgba($color--black, .2);
    transition: border-color $speed;

    &:hover {
        border-color: $color--cornflower-blue;

        .image-container {
            &:after {
                opacity: 0;
            }
        }
    }

    .image-container {
        position: relative;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: opacity $speed;
            background-color: rgba($color--brand-gold, .5);
            background-image: linear-gradient(0deg, rgba($color--black, .6), rgba($color--black, 0));
        }
    }

    .tile-image {
        display: block;
        width: 100%;
        max-height:190px;
    }

    .office-content {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .office-name {
        font-size: rem(24px);
        color: $color--black;
        text-transform: uppercase;
        letter-spacing: 1px;
        overflow: hidden;

        @include font-sans-serif-bold;
    }

    .office-address {
        font-size: rem(20px);
        line-height: 1.5;
        margin-top: 1rem;
        flex-grow: 1;
    }

    .office-number,
    .office-directions {
        font-size: rem(20px);
        color: $color--black;
        line-height: 1.5;

        @include font-sans-serif-bold;
    }

    .office-number {
        margin-top: 2rem;
    }
}
