.site-nav__primary {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $bp--phone) {
        display: block;
    }
}

.site-nav__primary-item {
    $margin-horizontal: 1rem;

    display: block;

    &:not(:first-child) {
        margin-left: 1.5625rem;
    }

    @media (max-width: $bp--phone) {
        margin-left: 0;
        margin-right: $margin-horizontal;
    }
}

.site-nav__primary-link {
    $color: $color--black;
    $color---mobile: $color--black;

    @include underline-link($color);

    color: $color;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.1rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
    transition: $transition-text-color;

    @media (max-width: $bp--phone) {
        @include underline-link($color: $color---mobile);

        color: $color--black;
        font-size: 1.75rem;
    }

    &:hover {
        @media (max-width: $bp--phone) {
            color: $color---mobile;
        }
    }
}
