.topics-tile {
    display: flex;

    .tile-hero-container {
        width: 40%;
        background-size: cover;
        background-position: 50% 50%;
        position: relative;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -10;
        }

        video {
            height: 100%;
            width: 100%;
        }

        .rich-text {
            padding: rem(24px);
        }

        .topics-summary {
            box-sizing: border-box;
            width: 100%;
            padding: rem(100px);
            position: relative;
        }
    }

    .tile-hero-container-video {
        iframe {
            width: 100%;
        }
    }

    .topics-sideitem:first-of-type {
        margin-top: 0px;
    }

    .topics-grid__side-item {
        box-sizing: border-box;
        padding: 40px 40px 30px 40px;
        // this is what we need REMOVE WHEN DONE
        @include hover-box-shadow( $background-color--initial: $color--white, $background-color--hover: $color--white, $color---initial: $color--white, $color---hover: $color--black, $z-index: 10 );

        a {
            transition: none;
        }

        &.tile-blue {
            background-color: $color--cornflower-blue;
        }

        &.tile-gold {
            background-color: $color--brand-gold;
        }

        &:hover {
            background-color: $color--floral-white;
            color: $color--black;

            .insights-grid__insight-abstract,
            .insights-grid__insight-heading {
                color: $color--black;
            }
        }
    }

    .topics-content {
        box-sizing: border-box;
        width: 60%;
        padding: rem(70px) rem(100px) rem(100px) rem(65px);
        position: relative;
    }

    .content-width-wrapper {
        max-width: 36.25rem;
    }

    .topics-title {
        font-size: rem(45px);
        margin-bottom: 3rem;
        @include font-serif;
    }

    .topics-title-alternate {
        font-size: rem(45px);
        margin-bottom: 3rem;
        @include font-serif;
        color: $color--black
    }

    .topics-pagetype {
        color: $color--white;
        text-transform: uppercase;
        font-size: 1rem;
        @include font-sans-serif-bold;
        margin-top: 0px;
    }

    .topics-pagename {
        color: $color--white;
    }

    .topics-heading {
        color: $color--cornflower-blue;
        text-transform: uppercase;
        margin-bottom: 25px;
        font-weight: bold;
        font-size: rem(25px);
    }

    .topics-item-heading {
        font-size: 1.75rem;
    }

    .topics-sideitem-container {
        padding: 55px 75px;
        text-align: left;
    }

    .topics-single-sideitem-container {
        margin-top: 50px;
        text-align: left;
    }

    .topics-single-sideitem-container:first-child {
        margin-top: 0px;
    }

    .topics-blue {
        color: $color--black;
    }

    .topics-gold {
        color: $color--black;
    }

    .topics-summary {
        line-height: 1.5;
        margin: 1rem 0 1rem 0;
        font-size: 1.25rem;
        line-height: 1.4;
    }

    .topics-summary-alternate {
        line-height: 1.5;
        margin: 1rem 0 1rem 0;
        font-size: 1.25rem;
        line-height: 1.4;
        color: $color--black;
    }

    .topics-link-container {
        margin-top: 2rem;
    }

    .topics-link {
        margin: 2px;
    }

    .topics-link-text {
        color: $color--white !important;
        border: 2px solid $color--white;

        &::after, &:hover {
            border: 2px solid $color--black;
            background-color: $color--black !important;
        }
    }

    .topics-link-text-alternate {
        border: 2px solid $color--white;
        color: $color--black !important;

        &::after, &:hover {
            border: 2px solid $color--white;
        }
    }

    &.content-left {
        .tile-hero-container {
            order: 1;
        }

        .tile-hero-container-video {
            order: 1;
        }

        .topics-content {
            &:after {
                border-right-color: red;
                left: 100%;
                transform: rotate(180deg);
            }
        }
    }

    &.content-right {
        .topics-content {
            display: flex;
            justify-content: flex-end;

            &:after {
                border-right-color: red;
                right: 100%;
            }
        }
    }

    &.tile-gold {
        background-color: $color--brand-gold;
        color: $color--white;

        .tile-hero-container {
            &:after {
                background-color: rgba($color--brand-gold, .2);
            }
        }

        .tile-hero-container-video {
            &:after {
                background-color: rgba($color--brand-gold, .2);
            }
        }

        .topics-content {
            &:after {
                border-right-color: $color--brand-gold;
            }
        }

        .topics-link {
            @include filler-link( $background-color--initial: transparent, $color---initial: $color--white, $background-color--hover: $color--white, $color---hover: $color--cornflower-blue );
        }
    }

    &.tile-white {
        background-color: $color--floral-white;

        .tile-hero-container {
            &:after {
                background-color: rgba($color--black, .2);
            }
        }

        .tile-hero-container-video {
            &:after {
                background-color: rgba($color--black, .2);
            }
        }

        .topics-content {
            &:after {
                border-right-color: $color--floral-white;
            }
        }

        .topics-summary {
            color: $color--black;
        }

        .topics-link {
            @include filler-link( $background-color--initial: $color--floral-white, $color---initial: $color--cornflower-blue, $background-color--hover: $color--cornflower-blue, $color---hover: $color--floral-white );
        }
    }

    &.tile-grey {
        background-color: $color-gray-darkest;

        .tile-hero-container {
            &:after {
                background-color: rgba($color--brand-gold, .3);
            }
        }

        .tile-hero-container-video {
            &:after {
                background-color: rgba($color--brand-gold, .3);
            }
        }

        .topics-content {
            &:after {
                border-right-color:$color-gray-darkest;
            }
        }

        .topics-title {
            color: $color--brand-gold;
        }

        .topics-summary {
            color: $color--white;
        }

        .topics-link {
            @include filler-link( $background-color--initial: $color-gray-darkest, $color---initial: $color--white, $background-color--hover: $color--white, $color---hover: $color-gray-darkest );
        }
    }

    &.tile-blue {
        background-color: $color--cornflower-blue;

        .tile-hero-container {
            &:after {
                background-color: $color--cornflower-blue;
            }
        }

        .tile-hero-container-video {
            &:after {
                background-color: rgba($color--cornflower-blue, .3);
            }
        }

        .topics-content {
            &:after {
                border-right-color: $color--cornflower-blue;
            }
        }

        .topics-title {
            color: $color--white;
        }

        .topics-summary {
            color: $color--white;
        }

        .topics-link {
            @include filler-link( $background-color--initial: transparent, $color---initial: $color--cornflower-blue, $background-color--hover: $color--white, $color---hover: $color--cornflower-blue );
        }
    }

    @include screen($bp--phone) {
        .topics-content {
            padding: rem(75px);
        }

        .topics-title {
            font-size: rem(28px);
        }
    }

    @include screen(600px) {
        flex-direction: column;

        .tile-hero-container {
            order: 1;
            padding-bottom: 60%;
            width: 100%;
            // Firefox modification
            body:last-child &, x:-moz-any-link {
                padding-bottom: 60vw;
            }
        }

        .tile-hero-container-video {
            order: 1;
            padding-bottom: 60%;
            width: 100%;
            // Firefox modification
            body:last-child &, x:-moz-any-link {
                padding-bottom: 60vw;
            }
        }



        &.tile-selector {
            .topics-content {
                width: 100%;

                &:after {
                    top: 100%;
                    left: 50%;
                    transform: rotate(-90deg) translate3d(0, -50%, 0);
                }
            }
        }
    }

    @include screen($bp--phone-small) {
        .topics-content {
            padding: rem(35px);
        }

        .topics-title {
            font-size: rem(24px);
        }
    }
}
