﻿.page-general-content {
    position: relative;
    $spacing: rem(36px);

    .content-wrapper-right {
        padding: 0 15% 0 15%;
        width: inherit;

        .accordion {
            max-width: inherit;
            width: inherit;
        }
    }

    .emergency-ul {
        font-size: 1.25rem;
    }

    .emergency-office {
        font-weight: bold;
        color: $color--satin-sheen;
        font-size: 1.3rem;
    }

    .emergency-ul li {
        padding-bottom: 1rem;
    }

    .emergency-ul li:before {
        line-height: 0.8;
    }

    .panel {
        padding: $spacing 0;

        > .content-wrapper-right > * {
            margin-top: $spacing;

            &:first-child {
                margin-top: 0;
            }
        }

        &:nth-of-type(odd) {
            background-color: $color--white;
            /*.related-content{
            background-color: initial;
            color: initial;
            .tab-item{

            }
            .insight-list{
                background-color: initial;
                .insight-content{
                    .date{
                        color: initial;
                    }
                    .insight-type{
                        color: initial;
                    }
                    .article-type{
                        color: initial;
                    }
                }
            }
        }*/
        }

        &:nth-of-type(even) {
            background-color: $color-gray-darkest;
            color: $color--white;
        }
    }

    .panel-news-insights-and-events {
        background-color: $color-gray-darkest !important;
    }

    .interior-infographic {
        background-color: transparent;
        padding: 0;
    }

    .related-content {
        padding: 0;
        width: auto;

        .rich-text .title {
            color: $color--brand-gold !important;

            &:hover {
                color: $color--floral-white !important;
            }
        }

        a {
            color: $color--floral-white;

            &:hover {
                color: $color--brand-gold;
            }
        }
    }

    .inline-list {
        .list-section {
            margin-bottom: 0;
        }
    }

    .people-list {
        padding-top: rem(60px);

        .section-header {
            color: $color--white;
        }

        .rich-text {
            color: $color--white;
        }

        > .people-list {

            &.active {
                padding: 0;
            }
        }
    }

    .stay-connected {
        padding: rem($section-spacing) 15%;
    }
}

.sub-nav{
    text-align: center;
    background-color: $color--brand-gold;

    .inline-list{
        max-width: 100%;

        .active{
            font-weight: 600;
        }

        .list-section{
            text-transform: uppercase;
            margin-top: rem(26px);
            margin-bottom: rem(26px);

            .list-item-link{
                color: $color--black;
                font-size: 16px;

                &:hover {
                    color:$color--white;
                    cursor: pointer;
                }
            }
        }
    }
}

.topic-tiles-grid{
    display: flex;
    flex-wrap: wrap;
    margin-right: 2rem * -1;
    margin-bottom: 2rem * -1;

    .topic-tile {
        margin-right: 2rem;
        margin-bottom: 2rem;
        width: calc(33.33% - #{2rem});
        background-color: $color--floral-white;
        display: flex;
        flex-direction: column;
        border-bottom: 5px solid transparent;
        box-shadow: 0 2px 5px 0 rgba($color--black, .2);
        transition: border-color .3s;

        &:hover {
            border-color: $color--cornflower-blue;

            .image-container {
                &:after {
                    opacity: 0;
                }
            }
        }

        .topic-heading {
            font-size: rem(24px);
            color: $color--black;
            text-transform: uppercase;
            letter-spacing: 1px;
            overflow: hidden;
        
            @include font-sans-serif-bold;
        }

        .image-container {
            position: relative;
        
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 1;
                transition: opacity .3s;
                background-color: rgba($color--brand-gold, .5);
                background-image: linear-gradient(0deg, rgba($color--black, .6), rgba($color--black, 0));
            }
        }

        .tile-image {
            display: block;
            width: 100%;
            max-height:190px;
        }

        .topic-content {
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        .topic-text {
            font-size: rem(20px);
            line-height: 1.5;
            margin-top: 1rem;
            flex-grow: 1;
        }
    }
}

@include screen(800px){
    .topic-tiles-grid {
        .topic-tile {
            width: calc(50% - #{2rem});
        }
    }
}

@include screen(500px){
    .topic-tiles-grid {
        .topic-tile {
            width: calc(100% - #{2rem});
        }
    }
}