.typeahead-list {
    position: absolute;
    border: 1px solid $color--brand-gold;
    background-color: $color--white;
    z-index: 10;
    padding: 5px;
    min-width: 500px;
    display: none;
    top: 100%;
    left: 0;
    right: 0;

    @include screen($bp--phone) {
        min-width: 0px;
        width: 100%;
    }

    .typeahead-list-item {
        cursor: pointer;
        display: block;

        &:hover,
        &:focus {
            color: $color--brand-gold;

            &:before {
                content: '> ';
            }
        }
    }

    &.active {
        display: block;
    }
}
