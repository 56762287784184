.page-redefined-landing {
    background-color: $color--black;
    color: $color--floral-white;
    padding-bottom: 60px;

    .visible {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }

    .visible-scale {
        opacity: 1;
        transform: scale3d(1,1,1);
        transition: all 0.5s ease-in-out;
    }

    .visible-slide {
        opacity: 1;
        transform: translateX(202px);
        transition: opacity 0.5s ease-in-out, transform 0.8s ease-in-out;

        @include screen($bp--phone) {
            transform: translateX(124px);
        }
    }

    .visible-slide-up {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    }

    .hidden {
        opacity: 0;
        transition: opacity 2s ease-in-out;
    }

    &__content-wrapper {
        width: inherit;
        padding: 0 15%;
        margin: 0 auto;
        max-width: 910px;
        z-index: 3;
        position: relative;
    }

    &__background {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__intro {
        position: absolute;
        left: 22%;
        top: 0;
    }

    &__intro-text {
        @include font-sans-serif-bold;
        text-transform: uppercase;

        &--one {
            color: $color--brand-gold;
            font-size: 166px;
            position: absolute;
            top: 37px;
            left: -207px;
            z-index: 0;
            opacity: 0;
        }

        &--two {
            font-size: 229px;
            color: rgba($color--brand-gold, 0.8);
            transform: scale3d(0.5,0.5,0.5);
            opacity: 0;
        }

        &--three {
            font-size: 55px;
            color: $color--floral-white;
            position: absolute;
            top: 105px;
            right: 0;
            transform: translateX(0);
            z-index: 2;
            opacity: 0;
        }
    }

    &__intro-text-wrap {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
    }

    &__header {
        width: 100%;
        margin-top: 81px;
        padding-top: 150px;
        opacity: 0;
        transform: translateY(100px);
    }

    &__title {
        @include font-sans-serif-bold;
        color: $color--half-baked;
        font-size: 72px;
        text-transform: uppercase;
    }

    &__body {
        font-size: 19px;
        line-height: 1.5;

        &--intro {
            @include font-sans-serif-bold;
        }

        &--section {
            position: relative;
            padding-left: 25px;
            line-height: 1.3;
            margin: 8px 0 35px;
            font-size: 19px;

            &::before {
                content: '';
                width: 6px;
                background-color: $color--brand-gold;
                display: block;
                position: absolute;
                top: 8px;
                left: 0;
                bottom: 4px;
            }
        }
    }

    &__section {
        margin-bottom: 60px;
        max-width: 950px;
        opacity: 0;
        transform: translateY(100px);
    }

    &__section-title-wrapper {
        display: flex;
        align-items: flex-end;
    }

    &__section-title {
        @include font-sans-serif-bold;
        font-size: 69px;
        text-transform: uppercase;

        &--highlight {
            color: $color--black;
            padding: 75px 10px 0;
            background-size: cover;
            background-repeat: no-repeat;
            margin: 0 10px 11px 0;
            font-size: 60px;
            line-height: 43px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(190,155,57,0.6);
            }
        }
    }

    &__highlight-word {
        position: relative;
    }

    .highlight-link {
        @include filler-link( $background-color--initial: $color--black, $color---initial: $color--floral-white, $background-color--hover: $color--floral-white, $color---hover: $color--black );
        text-decoration: none;
    }

    @include screen($bp--phone) {
        &__header {
            margin-top: 63px;
        }

        &__section-title-wrapper {
            display: block;
        }

        &__section-title {
            font-size: 62px;
            line-height: 1;

            &--highlight {
                font-size: 53px;
                line-height: 38px;
            }
        }

        &__content-wrapper {
            padding: 0 30px;
        }

        &__title {
            font-size: 60px;
            line-height: 1;
        }

        &__intro-text {
            &--one {
                font-size: 75px;
                left: -70px;
                top: 50px;
            }
            &--three {
                top: 97px;
            }
        }

        &__intro-text-wrap {
            top: -17px;
            left: -26px;
        }

    }
}

.page-redefined-landing__section {
    &:nth-of-type(odd) {
        .page-redefined-landing__body--section::before {
            background-color: $color--half-baked;
        }
        .page-redefined-landing__section-title--highlight::before {
            background: rgba($color--half-baked,0.6);
        }
    }
}