.bio-hero {
    $image-width: 625px;
    $image-padding: 4%;
    width: calc(100vw - #{$site-padding});
    position: relative;
    padding: 0 $site-padding 0 0;
    background-color: $color-gray-darkest;
    display: flex;
    align-items: flex-end;
    padding-top: 64px;

    a {
        @include font-sans-serif;
        color: $color--brand-gold;
        transition: $transition-text-color;

        &:hover {
            color: $color--floral-white;
        }

        p {
            color: $color--white;
        }
    }

    .image-container {
        display: flex;
        align-items: flex-end;
        width: calc(#{$image-width} - #{$image-padding} * 2);
        padding: 0 $image-padding;
        justify-content: center;
    }

    .bio-content {
        width: 60%;
        display: flex;
        align-items: flex-end;
        color: $color--floral-white;
        padding: rem(120px) 0 rem(64px);
        flex-direction: column;
        justify-content: flex-end;
        box-sizing: border-box;

        &.no-image {
            margin-left: 29%;
        }
    }

    .main-content {
        margin-bottom: rem(25px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    .name {
        @include font-serif-bold;
        font-size: rem(40px);
        letter-spacing: 3px;
        margin-bottom: rem(28px);

        sup {
            font-family: $font-name-sans-serif;
        }
    }

    $title-margin: 100px;

    .main-title {
        @include font-sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        font-size: rem(18px);
        display: block;
        margin-left: $title-margin * 0.5;
        line-height: 1.4;
        width: 100%;
        display: inline-flex;

        &:before {
            content: "";
            display: inline-block;
            width: rem($title-margin * 0.5);
            position: relative;
            left: -10px;
            top: 12px;
            border-top: solid 1px $color--floral-white;
        }

        @include screen($bp--tablet) {
            display: block;

            &:before {
                top: -5px;
            }
        }
    }

    .secondary-title {
        @include font-sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        font-size: rem(18px);
        display: block;
        margin-left: $title-margin;
    }

    .email {
        @include font-sans-serif;
        color: $color--brand-gold;
        font-size: rem(18px);
        margin-top: rem(8px);
        display: block;
        margin-left: $title-margin;
    }

    .offices {
        margin-bottom: 30px;
        width: 100%;
        display: flex;
    }

    .office-item {
        font-size: rem(18px);
        line-height: rem(30px);
        text-transform: uppercase;
        @include font-sans-serif;
        display: inline-block;

        + .office-item {
            margin-left: rem(80px);
        }
    }

    .office-link {
        display: block;
    }

    .phone {
        display: block;

        a {
            color: $color--white;
        }
    }

    .social-content {
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        a {
            color: $color--cornflower-blue;
            transition: $transition-text-color;

            &:hover {
                color: $color--floral-white;
            }
        }

        .addition-social-media {
            height: 1.25rem;
            width: 1.25rem;
            transition: 0.4s filter ease-in-out;

            &:hover {
                filter: brightness(4);
            }
        }
    }

    .social {
        font-family: $icomoon-font-family;
        font-size: rem(18px);

        .social-item {
            img {
                height: 1.25rem;
            }
        }
    }

    .share-item {
        font-size: rem(18px);
        transition: $transition-text-color;

        + .share-item {
            &:before {
                content: "|";
                margin: 0 10px 0 6px;

                &:hover {
                    color: $color--cornflower-blue;
                }
            }
        }

        &:hover {
            color: $color--floral-white;

            &:before {
                color: $color--cornflower-blue;
            }
        }
    }

    &.no-image {
        .bio-content {
            width: 100%;
        }

        .image-container {
            width: initial;
        }
    }

    @include screen($bp--md-small) {
        .image-container {
            padding: 0 $image-padding 0 0;
        }
    }

    @include screen($bp--tablet) {
        width: calc(100% - #{$site-padding});

        .image-container {
            width: calc(50% - #{$image-padding});
            padding-left: 0;
        }

        .bio-content {
            width: 50%;
        }
    }

    ;

    @include screen(820px) {
        display: block;
        height: auto;
        width: 100%;
        padding: 0;
        text-align: center;
        $approx-nav-height: 85px;
        min-height: 0;

        .image-container {
            padding-top: $approx-nav-height;
            margin: 0 auto;
            padding-right: 0;
            width: 40%;

            img {
                max-width: 250px;
            }
        }

        .name {
            font-size: rem(40px);
        }

        .main-title {
            margin-left: 0;

            &:before {
                display: none;
            }
        }

        .secondary-title, .email {
            margin-left: 0;
        }

        .bio-content {
            width: 100%;
            padding: 3rem 1rem 1rem 1rem;
            background-color: $color--black;
            text-align: center;

            &.no-image {
                padding-top: $approx-nav-height;
                margin-left: 0;
            }
        }

        .main-content {
            margin-bottom: 40px;
        }

        .offices {
            margin-bottom: 0;
            display: block;
        }

        .office-item {
            display: block;

            + .office-item {
                margin-left: 0;
                margin-top: 2rem;
            }
        }

        .social-content {
            display: block;
            height: 100px;
            margin-top: 2rem;
        }

        .share {
            margin-top: 2rem;
        }
    }

    .bio-share-tools {
        $spacing: 1.25rem;

        text-align: center;
        display: inline-block;
        z-index: 5;
        position: absolute;
        visibility: hidden;

        .share-tool-label {
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 1;
        }

        .share-tool-link-container {
            display: inline;
            margin-right: 1rem;
        }

        .share-tool-link {
            margin-top: $spacing;
            display: inline-block;
            color: $color--cornflower-blue;
            font-size: 1.75em;

            &:hover {
                color: $color--white;
            }
        }

        &.active {
            visibility: visible;
        }

        .share-tool-links {
            border: 0;
            margin-top: 0;
            margin-right: 1rem;
        }

        @include screen($bp--tablet) {
            position: relative;
        }
    }

    .disclaimer {
        width: 100%;
        color: $color--floral-white;
        padding-top: 3rem;
    }
}
