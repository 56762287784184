.capabilities-accordions {
    .accordions-grid {
        display: flex;
        justify-content: space-between;
    }

    .grid-column {
        width: calc(50% - 2rem);
        padding: 0 3rem 0 3rem;
    }

    .accordion {
        max-width: none;
    }

    .capability-link {
        font-size: rem(28px);
        color: $color--bistro-brown;
        padding: 15px 0;
        display: block;
        line-height: rem(38px);
        border-bottom: solid 1px $color--min-grey;

        @include font-sans-serif-bold;

        &:hover {
            color: $color--black;
        }
    }

    @include screen(700px){
        .accordions-grid {
            display: block;
        }

        .grid-column {
            width: auto;
        }
    }
}
