.locations-hero {
    $padding: 4.5rem;
    $vertical-spacing: 2rem;

    position: relative;
    padding: rem($header-height-desktop) + $padding 1rem $padding;
    text-align: center;
    color: $color--white;
    z-index: 1;


    .office-address-container {
        display: flex;
        flex-direction: column;
        
        @include screen($bp--tablet, 'min') {
            justify-content: space-evenly;
            flex-direction: row;
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    .background-image-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-size: cover;
        background-position: 50% 50%;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color--brand-gold, .5);
            background-image: linear-gradient(0deg, rgba($color--black, .6), rgba($color--black, 0));
        }
        // Firefox modification
        body:last-child &:after, x:-moz-any-link {
            background-image: -moz-linear-gradient(90deg, rgba($color--black, .6), rgba($color--black, 0));
        }
    }

    .location-name {
        max-width: none;
        font-size: rem(47px);
        letter-spacing: 3px;
        color: $color--white;

        @include font-serif-bold;
        &--black {
            color: #000000;            
        }
    }

    .location-address {
        margin-top: $vertical-spacing;
        font-size: rem(20px);
        
        @include font-sans-serif-bold;
        &--black {
            color: #000000;            
        }
    }

    .location-contact-numbers {
        margin-top: $vertical-spacing * 1.5;
                &--black {
            color: #000000;            
        }
    }

    .location-contact-number {
        @include font-sans-serif-bold;
        font-size: rem(20px);

        & + & {
            margin-top: .5rem;
        }
    }

    .number-type {
        @include font-sans-serif-bold;
        text-transform: uppercase;
        color: $color--white;
        margin-right: .5rem;
    }

    .location-link {
        @include filler-link( $background-color--initial: transparent, $color---initial: $color--white, $background-color--hover: $color--brand-gold, $color---hover: $color--black);
        margin: $vertical-spacing .5em 0;
        text-transform: uppercase;
        padding: 1em 2em;
        display: inline-block;

        @include font-sans-serif-bold;

        @include screen($bp--tablet, 'min') {
            &:hover {
                color: $color--white;
            }
        }
    }

    .location-link-black {
        @include filler-link( $background-color--initial: transparent, $color---initial: $color--brand-gold, $background-color--hover: $color--brand-gold, $color---hover: $color--white );
        margin: $vertical-spacing .5em 0;
        text-transform: uppercase;
        padding: 1em 2em;
        display: inline-block;
        color: #ffffff;
        background:#000000;
        @include font-sans-serif-bold;

        @include screen($bp--tablet, 'min') {
            &:hover {
                color: $color--white;
            }
        }

            &:before {
                    box-sizing: border-box;
                    content: "";
                    display: block;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: -10;
            }

            &:after{
                    bottom: 0;
                    content: "";
                    height: 120%;
                    left: -10%;
                    position: absolute;
                    transition: width .5s;
                    width: 0;
                    z-index: -5;
                    background-color: #be9b39;
            }
    }
}
