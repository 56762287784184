// Block
@use "sass:math";

.insights-grid {
    display: flex;
    position: relative;
    z-index: $insights-grid;
    padding-top: 2rem;

    @media (max-width: $bp--tablet) {
        display: block;
    }

    &-control {
        position: absolute;
        bottom: 14px;
        right: 34%;

        @media (max-width: $bp--tablet) {
            top: 39%;
            right: 16px;
            bottom: unset;
        }

        &-play {
            display: none;
        }
    }

    .carousel-control {
        $angle-border-style: 3px solid $color--white;
        $border-style: 1px solid darken($color--brand-gold, 10);

        background-color: $color--brand-gold;
        color: $color--white;
        display: block;
        width: 100%;
        font-size: 1.5rem;
        text-transform: uppercase;
        padding: .5rem;
        transition: background-color .3s;

        &:after,
        &:before {
            $size: 20px;

            display: inline-block;
            width: $size;
            height: $size;
        }

        &.carousel-previous {
            border-bottom: $border-style;

            &:before {
                content: '';
                border-top: $angle-border-style;
                border-left: $angle-border-style;
                transform: rotate(45deg) translate3d(0, 50%, 0);
            }
        }

        &.carousel-next {
            border-top: $border-style;

            &:before {
                content: '';
                border-bottom: $angle-border-style;
                border-right: $angle-border-style;
                transform: rotate(45deg) translate3d(-50%, 0%, 0);
            }
        }

        &:hover {
            background-color: darken($color--brand-gold, 10);
        }
    }
}

// Element
.insights-grid__insight-item {
    box-sizing: border-box;

    &:not(:last-of-type) {
        border-bottom: 1px solid $color--half-baked;
    }

    @include hover-box-shadow( $background-color--initial: $color--half-baked, $background-color--hover: $color--white, $color---initial: $color--black, $color---hover: $color--black, $z-index: 10 );

    a {
        transition: none;
    }

    &:hover {
        background-color: $color--floral-white;
        color: $color--black;

        .insights-grid__insight-abstract,
        .insights-grid__insight-heading {
            color: $color--black;
        }
    }
}

.insights-grid__insight-item--alternate {    

    &:not(:last-of-type) {
        border-bottom: 1px solid $color--brand-gold;
    }

    @include hover-box-shadow( $background-color--initial: $color--brand-gold, $background-color--hover: $color--white, $color---initial: $color--black, $color---hover: $color--black, $z-index: 10 );    
}

.insights-grid__insight-abstract {
    color: $color--white;
}

.insights-grid__insight-link {
    display: block;
    padding: 6.6% 9.8%;

    @media (max-width: $bp--tablet) {
        text-align: center;
    }
}

$color--test: blue;


.insights-grid__hero-description {
    color: $color--white;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 1.4;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    @media (max-width: $bp--tablet) {
        margin-left: auto;
        margin-right: 0;
    }

    @media (max-width: $bp--phone) {
        $mobile-base-font: 14;

        font-size: math.div(1rem * 18, $mobile-base-font);
        line-height: math.div(1rem * 32, $mobile-base-font);
    }
}

.insights-grid__hero-link {
    @include filler-link;
    margin-top: 2.6rem;
}

.insights-grid__insight-heading {
    $margin-vertical: 0.8rem;

    color: $color--black;
    font-size: 2rem;
    letter-spacing: 1.3px;
    margin-bottom: $margin-vertical;
    margin-top: $margin-vertical;
}

.insights-grid__insight-source {
    color: $color--black;
    font-size: 1rem;
    font-weight: 700;
    margin-left: 1rem;
}

.insights-grid__insight-source-bottom{
    color: $color--black;
    font-size: 1rem;
    font-weight: 700;
}

.insights-grid__insight-date {
    span {
        color: $color--black;
        font-size: 0.875rem;
        font-weight: 700;

        &:nth-of-type(1) {
            text-transform: uppercase;
        }

        &:nth-of-type(2) {
            &::before {
                content: "| ";
                white-space: pre;
            }
        }
    }
}

.insights-list-container {
    flex-basis: 60%;
    order: -1;
}

// Container for percentage padding: https://drafts.csswg.org/css-flexbox/#item-margins
.insights-grid__hero-container {
    flex-basis: 40%;
    position: relative;
    z-index: -1;
}

.insights-grid__insights-list {
    $size: 15px;

    background-color: $color--half-baked;
    position: relative;

    .slick-dots {
        position: absolute;
        bottom: 0;
        right: -5%;
        width: $size;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        @media (max-width: $bp--tablet) {
            bottom: 100%;
            right: auto;
            flex-direction: row;
            margin-left: 10px;

            li {
                margin: 0 5px 0;
            }
        }

        li {
            &.slick-active {
                &:before {
                    color: $color--brand-gold;
                }

                a {
                    display: inline-block;
                    color: $color--brand-gold;
                    font-size: 3rem;
                    vertical-align: top;
                    padding-top: 40%;
                }

                button {
                    background-color: $color--white;
                    position: relative;

                    &:before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 50%;
                        background-color: $color--brand-gold;
                        top: -2px;
                        left: -2px;
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 50%;
                        height: 50%;
                        border-radius: 50%;
                        background-color: $color--light-gold;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }
            }
        }

        button {
            $size: 1.25rem;

            position: relative;
            display: block;
            width: $size;
            height: $size;
            border-radius: 50%;
            background-color: $color--white;
            transition: background-color .3s;
            color: rgba($color--black, 0);
            margin-bottom: 1rem;
        }
    }
}

.insights-grid__insights-list--alternate {    
    background-color: $color--brand-gold;
}

.insights-grid__insights-list,
.insights-grid__hero-container {
    box-sizing: border-box;
}

.insights-grid__hero-heading {
    color: $color--white;
    @include font-serif;
    font-size: 1.96875rem;
}

.insights-grid__hero {
    padding: 18.8% 12%;
    text-align: right;

    @media (max-width: $bp--tablet) {
        padding: 6% 9.3%;
        text-align: center;
    }

    @media (max-width: $bp--phone) {
        padding: 3rem;
    }
}

.insights-grid__hero-background-image {
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $insights-grid__hero-background-image;

    &::before {
        background-color: rgba($color--black, 0.6);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}