.insight-list {
    background-color: $color-gray-darkest;
    visibility: hidden;
    height: 0;
    overflow: hidden;

    &.active {
        visibility: visible;
        height: auto;
        padding: 0 $site-padding*2 $section-spacing;
    }

    .insight-container {
    }

    $insight-item-padding: 1.25rem;

    .insight-item {
        border-bottom: solid 1px $color--gondola;
        display: flex;
        align-items: flex-start;

        &:first-child {
            padding-top: rem(60px);
        }

        &:last-child {
            padding-bottom: rem(60px);
            border-bottom: 0;
        }
    }

    .insight-content {
        width: 100%;
        margin: $insight-item-padding 0;
    }

    .event-date {
        border: 1px solid $color--white;
        color: $color--white;
        padding: 1rem;
        text-align: center;
        min-width: rem(100px);
        margin-right: 3rem;
    }

    .event-day-month {
        text-transform: uppercase;
        display: block;
        white-space: nowrap;
        font-size: rem(24px);

        @include font-sans-serif-bold;
    }

    .event-year {
        font-size: rem(36px);
    }

    $insight-line-spacing: 16px;

    .date {
        color: $color--floral-white;
        letter-spacing: 0px;
        font-size: rem(16px);
        margin-right: 8px;
        @include font-sans-serif-bold;
    }

    .event-location,
    .insight-type {
        @include font-sans-serif-bold;
        color: $color--floral-white;
        font-size: rem(16px);
        text-transform: uppercase;
    }

    .title-section {
        position: relative;
        display: flex;
        margin-top: $insight-line-spacing;
    }

    $title-font-size: 29px;

    .title {
        color: $color--brand-gold;
        font-size: rem($title-font-size);
        @include font-serif-bold;
        width: 75%;
        letter-spacing: 1.5px;
        transition: $transition-text-color;
        line-height: 1.15;
        flex-grow: 1;
        background: none;
        text-decoration: none;

        &:hover {
            color: $color--floral-white;
        }
    }

    .time-to-read {
        $time-font-size: 19px;
        display: inline-block;
        width: 25%;
        text-align: right;
        font-weight: bold;
        font-size: rem($time-font-size);
        text-transform: lowercase;
        margin-top: .5rem;
        color: $color--min-grey;

        &:before {
            content: "";
            width: 40px;
            display: inline-block;
            border-bottom: solid 2px $color--min-grey;
            position: relative;
            bottom: $time-font-size * 0.25;
            margin-right: 12px;
        }
    }

    .article-type {
        font-size: rem(18px);
        font-style: italic;
        color: $color--floral-white;
        margin-top: rem($insight-line-spacing);
        display: inline-block;
    }

    .article-summary {
        margin-top: 1rem;
        color: $color--white;
        line-height: 1.5;
        font-weight: 600;
    }

    .view-more {
        @include font-sans-serif;
        text-align: center;
        color: $color--half-baked;
        padding: rem($section-spacing) 0 0 0;
        font-size: rem(20px);
        cursor: pointer;
        position: relative;
        margin: 0 auto;
        display: block;
    }

    .no-insight-container {
        padding-top: $section-spacing;
        text-align: center;

        .no-insight-message {
            @include font-serif-bold;
            font-size: rem(28px);
            letter-spacing: 1px;
            display: inline-block;
            color: $color--floral-white;
        }
    }

    @include screen($bp--phone) {
        a.title {
            font-size: rem(28px);
        }

        .title-section {
            flex-direction: column;
        }

        .title,
        .time-to-read {
            width: auto;
        }

        .time-to-read {
            width: auto;
            text-align: left;

            &:before {
                content: none;
            }
        }

        padding: 0 $site-padding-mobile * 2 $section-spacing;

        .event-date {
            min-width: rem(80px);
            margin-right: 1.5rem;
        }

        .event-day-month {
            font-size: rem(20px);
        }

        .year {
            font-size: rem(24px);
        }
    }
}
