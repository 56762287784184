.article-share-tools {
    $spacing: 1.25rem;

    text-align: center;
    display: inline-block;

    .share-tool-label {
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 1;
    }

    .share-tool-links {
        margin-top: $spacing;
        border-top: 1px solid $color-gray-darkest;
    }

    .share-tool-link {
        margin-top: $spacing;
        display: inline-block;
        color: $color--brand-gold;
        font-size: 1.75em;

        &:hover {
            color: $color--black;
        }
    }

    .alert-subscribe-link {
        @include font-sans-serif-bold;
        color: $color--black;
        text-transform: uppercase;
        padding-bottom: 1em;
        transition: color 0.4s ease-in-out;

        &:hover {
            color: $color--dark-gray;
        }
    }
}
