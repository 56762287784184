.inline-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: $max-width-chars;

    .label-section {
        width: 25%;
        display: inline-block;
        font-size: rem(20px);
        color: $color-gray-darkest;
        margin-top: 5px;
        margin-right: .5rem;

        @include font-sans-serif-bold;

        @media (max-width: $bp--phone) {
            width: 30%;
            padding-right: 1rem;
        }
    }

    .list-section {
        width: calc(75% - 1.5rem);
        display: inline-block;
        margin-bottom: rem(30px);
        flex-grow: 1;
        padding-left: 1rem;

        &.rich-text {
            p:last-of-type {
                margin-bottom: 0;
            }
        }

        @media (max-width: $bp--phone) {
            width: calc(70% - 2.5rem);
        }
    }

    .list-item-link {
        @include font-sans-serif;
        font-size: rem(20px);
        line-height: 1.5;
        color: $color--bistro-brown;
        position: relative;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: $color--black;
        }

        + .list-item-link {
            &:before {
                content: "|";
                color: $color-gray-darkest;
                background: none;
                margin: 0 10px 0 6px;
            }
        }
    }

    .list-item {
        &:after {
            content: ",";
            margin: 0 4px 0 -2px;
            padding-left: 2px;
            display: inline-block;
            width: 6px;
        }

        &:last-child {
            &:after {
                content: "";
                display: none;
            }
        }
    }
}
