.page-capabilities-landing {
    $spacing: rem(72px);

    .section-header {
        margin-left: 3rem;
        display: inline-block;
    }

    .listing-section {
        padding: $spacing 0;
        background-color: $color--white;

        &.hidden {
            display: none;
        }
    }

    .btn-expand-all {
        margin: .5rem 3rem 0.5rem 3rem;
        min-width: rem(175px);
        display: inline-block;
        float: right;

        @include filler-link( $background-color--initial: $color--white, $color---initial: $color--black, $background-color--hover: $color--brand-gold, $color---hover: $color--black ); @include font-sans-serif-bold;
    }

    .search-results {
        background-color: $color-gray-darkest;
    }

    .insight-list {
        background-color: inherit;
    }

    @include screen ($bp--phone) {
        .search-filters {
            .search-content {
                height: auto;
            }

            .filter-headings {
                display: none;
            }
        }

        .search-results {
            display: none;

            &.active {
                display: block;
            }
        }

        .btn-expand-all {
            float: none;
            display: block;
        }
    }
}
