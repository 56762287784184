// Vars
$home-main__after: 5;

// Block
.home-main {
    $padding-horizontal: 6.3%;

    background-color: $color--white;
    padding-left: $padding-horizontal;
    padding-right: $padding-horizontal;
    position: relative;

    @media (max-width: $bp--tablet) {
        $padding-horizontal: 2.5%;

        padding-left: $padding-horizontal;
        padding-right: $padding-horizontal;
    }

    @media (max-width: $bp--phone) {
        $padding-horizontal: 0;

        padding-left: $padding-horizontal;
        padding-right: $padding-horizontal;
    }

    &::after {
        background-color: $color--white;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: $home-main__after;
    }

    > * {
        position: relative;
        z-index: 100; //TODO: what to do about this; it doesnt go well with z-index.scss
    }
}
