.people-list{
    $image-width: 245px;
    $middle-width: 60%;
    background-color: $color-gray-darkest;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    &.active{
        visibility: visible;
        height: auto;
        padding: 0 $site-padding $section-spacing;
    }
    .person-item{
        display: flex;
        flex-grow: row;
        border-bottom: solid 2px $color--gondola;
    }
    .image-container{
        width: $image-width;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
            display: block;
        }
    }
    .info-container{
        width: calc(100% - #{$image-width});
        display: flex;
        justify-content:flex-end;
    }
    $vertical-padding:30px;
    .middle-info{
        width: $middle-width;
        order: 2;
        position: relative;
        display: flex;
        justify-content:space-between;
        flex-direction: column;
        .lower-info{
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
    .right-info{
        width: calc(100% - #{$middle-width});
        position: relative;
        order: 3;
        text-align: right;
        display: flex;
        justify-content:space-between;
        flex-direction: column;
        .lower-info{
            justify-content: flex-end;
        }
    }
    .upper-info{
        height: auto;
        padding-top: $vertical-padding * 2;
    }
    .lower-info{
        display: flex;
        align-items: flex-end;
        padding-bottom: $vertical-padding;
        flex-wrap: wrap;
    }
    .name{
        font-size:rem(30px);
        color: $color--brand-gold;
        line-height: 1.1;
        letter-spacing: 2px;
        @include font-serif-bold;
        margin-bottom: 8px;
        display: inline-block;
        transition: $transition-text-color;
        &:hover{
            color: $color--floral-white;
        }
    }
    .level,
    .title{
        @include font-sans-serif;
        text-transform: uppercase;
        font-size: rem(16px);
        letter-spacing: 2px;
        color: $color--floral-white;
        display: block;
        line-height: 1.5;
        margin-bottom: 8px;
    }
    .email {
        @include font-sans-serif-bold;
        font-size: rem(18px);
        display: block;
        color: $color--half-baked;
        margin-bottom: 16px;
        transition: $transition-text-color;
        &:hover{
            color: $color--floral-white;
        }
    }
    .phone{
        @include font-sans-serif;
        color: $color--half-baked;
        letter-spacing: 1px;
        font-size: rem(18px);
        transition: $transition-text-color;
        &:hover{
            color: $color--floral-white;
        }

        &:before{
            content: '+';
        }
    }
    .office{
        @include font-sans-serif;
        line-height: 33px;//should be same as name
        color: $color--half-baked;
        transition: $transition-text-color;
        &:hover{
            color: $color--floral-white;
        }
    }
    a.service{
        @include filler-link(
            $background-color--initial: $color-gray-darkest,
            $color---initial: $color--white,
            $background-color--hover: $color--half-baked,
            $color---hover: $color--black
        );
        @include font-sans-serif-bold;
        letter-spacing: 1px;
        padding: 8px 20px;
        margin-bottom: 4px;
        + .service{
            margin-left: 8px;
        }
    }
    .view-more{
        @include font-sans-serif;
        text-align: center;
        color: $color--half-baked;
        padding: rem($section-spacing) 0 0 0;
        font-size: rem(20px);
        cursor: pointer;
        position: relative;
        margin: 0 auto;
        display: block;
    }
    .no-people-container{
        padding-top: $section-spacing;
        text-align: center;
        .no-people-message{
            @include font-serif-bold;
            font-size: rem(28px);
            letter-spacing: 1px;
            display: inline-block;
            color: $color--floral-white;
        }
    }
    @include screen(860px){
        &.active{
            padding: 0 $site-padding-mobile $section-spacing;
        }
        $mobile-image-size: 245px;
        .image-container{
            width: $mobile-image-size;
        }
        .info-container{
            flex-wrap: wrap;
            width: calc(100% - #{$mobile-image-size});
        }
        .middle-info{
            display: block;
            width: 100%;
            height: auto;
        }
        .right-info{
            display: block;
            width: 100%;
            height: auto;
            text-align: left;
            .upper-info{
                padding-top: 0;
            }
            .lower-info{
                display: block;
            }
        }
        a.service{
            margin-bottom: 0;
            display: inline-block;
            +.service{
                margin-left: 0;
            }
        }
        .upper-info{
            height: auto;
            padding-top: 30px;
        }
        .lower-info{
            height: auto;
            padding-bottom: 14px;
        }
        .email{
            margin: 4px 0;
        }
    };
    @include screen(550px){
        $mobile-image-size: 175px;
        .image-container{
            display: none;
        }
        .info-container{
            flex-wrap: wrap;
            width: 100%;
        }     
    .person-item{
        min-height: inherit;
    }
    };
}
