.section-rich-text {
    .hide-header {
        display: none;
    }

    .rich-text {
        p {
            color: inherit;
        }
    }
}
