.article-background-info {

    .content-wrapper-right {
        position: relative;
        p{
            color: $color--black;
        }
    }

    .section-label {
        $left-width: 27%;
        $spacing: 50px;

        position: absolute;
        top: 0;
        right: calc(100% - #{$left-width} + #{$spacing});
        width: calc(#{$left-width} - #{$spacing});
        min-width: 200px;
        color: $color--white;
        font-size: rem(32px);
        line-height: 1.5;
        padding: 1rem;
        text-align: right;
        background-color: $color--cornflower-blue;

        @include font-serif-bold;
    }

    .rich-text {
        p {
            color: $color--black;
        }
    }

    .related-links {
        margin-top: 3rem;

        &.nopad {
            margin-top: 0;
        }

      .btn-link {
        margin-top: 2rem;

        @include filler-link(
            $background-color--initial: $color--black,
            $color---initial: $color--white,
            $background-color--hover: $color--brand-gold,
            $color---hover: $color--white
        );

        @include font-sans-serif-bold;
    }
    }

    @include screen($bp--phone){
        .section-label {
            position: static;
            text-align: left;
            display: inline-block;
            min-width: 0;
            background-color: none;
            width: auto;
            margin-bottom: 2rem;
        }
    }
}
