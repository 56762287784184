@use "sass:math";

.alphabet-search-container {
    padding: rem($section-spacing) 2*$site-padding;
    background-color: $color--floral-white;
    width: calc(100% - 4 * #{$site-padding});
    min-height: rem(27px);

    .alphabet-search {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .letter {
            font-size: rem(24px);
            text-align: center;
            width: math.div(100, 26) * 1%;
            color: $color--dark-gray;
            display: inline-block;
            @include font-serif-bold;

            &.letter-active {
                $animation: 0.2s ease-in-out;
                color: $color-gray-darkest;
                cursor: pointer;
                transition: transform $animation, color $animation;

                &:hover, &.active {
                    transform: scale(2);
                    transform-origin: center;
                    color: $color--bistro-brown;
                }
            }
        }
    }

    &.empty {
        padding: 0 0 60px 0;
        width: inherit;
        min-height: 0;
    }

    @include screen($bp--tablet) {
        padding: 3.75rem 0;
        width: 100%;
         .alphabet-search {
             display: inherit;
             }
    }

    @include screen($bp--phone) {
        padding: rem($section-spacing * 0.5) 2*$site-padding-mobile;
        width: calc(100% - 4 * #{$site-padding-mobile});
        $letters-per-line: 10;

        .alphabet-search {
                display: flex;
            .letter {
                width: math.div(100%, $letters-per-line);
                margin: 10px 0;
            }
        }
    }
}
