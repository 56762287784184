.bio-main{
    position: relative;
    .main-content{
        padding: 0 $detail-padding-right 0 $detail-padding-left;
        background: $color--white;
        width: $detail-content-width;

        p{
            color: $color-gray-darkest;
        }
        color: $color-gray-darkest;
    }
    @include screen($bp--phone){
        .main-content{
            padding: 0 $site-padding-mobile;
            width: calc(100% - 2 * #{$site-padding-mobile});
        }
    };
}
