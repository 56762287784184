.highlight-tile {
    display: flex;

    .tile-hero-container {
        width: 40%;
        background-size: cover;
        background-position: 50% 50%;
        position: relative;
        padding-top: 20%;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -10;
        }

        video {
            height: 100%;
            width: 100%;
        }

        .rich-text {
            padding: rem(24px);
        }

        .highlight-summary {
            box-sizing: border-box;
            width: 100%;
            padding: rem(100px);
            position: relative;
        }
    }

    .tile-hero-container-video {
        iframe {
            width: 100%;
        }
    }

    .highlight-content {
        box-sizing: border-box;
        width: 60%;
        padding: rem(100px);
        position: relative;

        &:after {
            $size: 1.5rem;

            content: '';
            display: inline-block;
            width: 0;
            position: absolute;
            top: 25%;
            border: $size solid transparent;
            z-index: 1;
        }
        ol, ul {
            list-style-type: disc;
            padding-left: 20px;
        }
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

    }

    .highlight-content-alternate {
        background-size: cover;
        background-position: 50% 50%;
        position: relative;
    }

    .content-width-wrapper {
        max-width: rem(500px);
    }

    .highlight-title {
        font-size: rem(32px);

        @include font-serif-bold;
    }

    .highlight-title-alternate {
        font-size: rem(32px);
        color: $color--black;

        @include font-serif-bold;
    }

    .highlight-summary {
        line-height: 1.5;
        margin: 1rem 0 1rem 0;
    }

    .highlight-summary-alternate {
        color: $color--black;
        line-height: 1.5;
        margin: 1rem 0 1rem 0;
    }

    .highlight-link-container {
        margin-top: 2rem;
    }

    // .highlight-link {
    //     color: $color--black !important;
    //     margin: 2px;

    //     &::after, &:hover {
    //         background-color: $color--white !important;
            
    //     }
    // }

    .highlight-link-alternate {
        color: $color--black !important;
        margin: 2px;

        &::after, &:hover {
            background-color: $color--white !important;
        }
    }

    &.content-left {
        .tile-hero-container {
            order: 1;
        }

        .tile-hero-container-video {
            order: 1;
        }

        .highlight-content {
            &:after {
                border-right-color: red;
                left: 100%;
                transform: rotate(180deg);
            }
        }
    }

    &.content-right {
        .highlight-content {
            display: flex;
            justify-content: flex-end;

            &:after {
                border-right-color: red;
                right: 100%;
            }
        }
    }

    &.tile-gold {
        background-color: $color--brand-gold;
        color: $color--black;

        .tile-hero-container {
            &:after {
                background-color: rgba($color--black, .2);
            }
        }

        .tile-hero-container-video {
            &:after {
                background-color: rgba($color--black, .2);
            }
        }

        .highlight-content {
            &:after {
                border-right-color: $color--brand-gold;
            }
        }

        .highlight-link {
            @include filler-link( $background-color--initial: $color--brand-gold, $color---initial: $color--black, $background-color--hover: $color--white, $color---hover: $color--black );
            text-decoration: none;
        }
    }

    &.tile-white {
        background-color: $color--floral-white;

        .tile-hero-container {
            &:after {
                background-color: rgba($color--black, .2);
            }
        }

        .tile-hero-container-video {
            &:after {
                background-color: rgba($color--black, .2);
            }
        }

        .highlight-content {
            &:after {
                border-right-color: $color--floral-white;
            }
        }

        .highlight-title {
            color: $color--bistro-brown;
        }

        .highlight-summary {
            color: $color--black;
        }

        .highlight-link {
            @include filler-link( $background-color--initial: $color--floral-white, $color---initial: $color--black, $background-color--hover: $color--brand-gold, $color---hover: $color--black );
            border-color: $color--brand-gold;
            text-decoration: none;
        }
    }

    &.tile-grey {
        background-color: $color-gray-darkest;

        .tile-hero-container {
            &:after {
                background-color: rgba($color--brand-gold, .3);
            }
        }

        .tile-hero-container-video {
            &:after {
                background-color: rgba($color--brand-gold, .3);
            }
        }

        .highlight-content {
            &:after {
                border-right-color: $color-gray-darkest;
            }
        }

        .highlight-title {
            color: $color--brand-gold;
        }

        .highlight-summary {
            color: $color--white;
        }

        .highlight-link {
            @include filler-link( $background-color--initial: $color-gray-darkest, $color---initial: $color--white, $background-color--hover: $color--white, $color---hover: $color-gray-darkest );
            text-decoration: none;
        }
    }

    &.tile-blue {
        background-color: $color--cornflower-blue;

        .tile-hero-container {
            &:after {
                //background-color: rgba($color--brand-gold, .3);
                background-color: $color--black;
            }
        }

        .tile-hero-container-video {
            &:after {
                background-color: rgba($color--brand-gold, .3);
            }
        }

        .highlight-content {
            &:after {
                border-right-color: $color--cornflower-blue;
            }
        }

        .highlight-title {
            color: $color--black;
        }

        .highlight-summary {
            color: $color--black;
        }

        .highlight-link {
            @include filler-link( $background-color--initial: $color--cornflower-blue, $color---initial: $color--black, $background-color--hover: $color--white, $color---hover: $color--black );
            text-decoration: none;
        }
    }

    @include screen($bp--phone) {
        .highlight-content {
            padding: rem(75px);
        }

        .highlight-title {
            font-size: rem(28px);
        }
    }

    @include screen(600px) {
        flex-direction: column;

        .tile-hero-container {
            order: 1;
            padding-bottom: 60%;
            width: 100%;
            // Firefox modification
            body:last-child &, x:-moz-any-link {
                padding-bottom: 60vw;
            }
        }

        .tile-hero-container-video {
            order: 1;
            padding-bottom: 60%;
            width: 100%;
            // Firefox modification
            body:last-child &, x:-moz-any-link {
                padding-bottom: 60vw;
            }
        }



        &.tile-selector {
            .highlight-content {
                width: 100%;

                &:after {
                    top: 100%;
                    left: 50%;
                    transform: rotate(-90deg) translate3d(0, -50%, 0);
                }
            }
        }
    }

    @include screen($bp--phone-small) {
        .highlight-content {
            padding: rem(35px);
        }

        .highlight-title {
            font-size: rem(24px);
        }
    }
}
