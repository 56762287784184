.page-bio-detail {
    .related-content {
        $spacing: 3rem;

        padding-top: $spacing;
        padding-bottom: $spacing;
    }

    .disclaimer {
        margin-bottom: rem(30px);
        line-height: 1;
        font-style: italic;
    }
}

.inline-list {   
    .list-section {
        padding-left: 0;
    }
}