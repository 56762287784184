﻿
.type-ahead-container{
    margin-top: 5.7rem;
    position:absolute;
    width: 30%;
    border: 1px solid $color--brand-gold;
    .type-ahead{
        background-color: $color--white;
        position: relative;
        z-index: 999;
        display: inline-block;
        color: $color--black;
        padding: 5px;
        width: 100%;
        cursor: pointer;
        transition: color .2s linear;
        &:hover{
            color: $color--gray-medium;
        }
    }
    &:focus{
        display: block;
    }
    @include screen($bp--phone){
        margin-top: 4rem;
        width: calc(100% - 14vw);
    }
}

.site-nav__search-form{
    .type-ahead-container{
        margin-top: 0;
        width: 100%;
        font-size: 1.5rem;
     @include screen($bp--phone){
         width: calc(100% - 14vw);
    }
    }
}