.accordion-offices-content {
    $spacing: 2rem;

    .rich-text{
        p {
            color: $color--black;
            max-width: none;
        }
    }

    .office-tiles-grid {

        display: flex;
        flex-wrap: wrap;
        margin-right: $spacing * -1;
        margin-bottom: $spacing * -1;

        .office-tile {
            margin-right: $spacing;
            margin-bottom: $spacing;
            width: calc(33.33% - #{$spacing});
        }
    }

    @include screen(800px){
        .office-tiles-grid {
            .office-tile {
                width: calc(50% - #{$spacing});
            }
        }
    }

    @include screen(500px){
        .office-tiles-grid {
            .office-tile {
                width: calc(100% - #{$spacing});
            }
        }
    }
}
