.page-capabilities-detail {
    position: relative;
    $spacing: rem(72px);

    .accordion-panel {
        padding: 2.25rem 0;
    }

    .panel {
        padding: $spacing 0;

        > .content-wrapper-right > * {
            margin-top: $spacing;

            &:first-child {
                margin-top: 0;
            }
        }

        &:nth-of-type(odd) {
            background-color: $color--white;
        }

        &:nth-of-type(even) {
            background-color: $color-gray-darkest;
            color: $color--floral-white;

            .related-capabilities {
                .capability-link {
                    color: $color--brand-gold;

                    &:hover {
                        color: $color--floral-white;
                    }
                }
            }
        }
    }

    .interior-infographic {
        background-color: transparent;
        padding: 0;
    }

    .related-content {
        padding: 0;
        width: auto;
    }

    .inline-list {
        .list-section {
            margin-bottom: 0;
        }
    }

    .rich-text {
        h2 {
            font-size: 1rem;
        }
    }
}
