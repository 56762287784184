.page-insights-landing,
.page-news-landing,
.page-events-landing {
    $spacing: rem(72px);

    .insight-list-wrpr {
        background-color: $color-gray-darkest;
        padding: $spacing $site-padding*2 $section-spacing;

        &.search-results {
            padding: 0 $site-padding*2 $section-spacing;
        }

        .section-header {
            color: $color--white;
            display: inline-block;
            @include screen($bp--phone) {
                display: inherit;
            }
        }
    }

    .insight-list {
        padding: 0;
        background-color: inherit;
        transition: height 1s linear, visibility 1s linear;

        .insight-item {
            &:first-child {
                padding-top: rem(40px) !important;
            }
        }
    }

    .insights-list__subscribe-link {
        @include filler-link;
        padding: 0.9375rem 3.5rem;
        float: right;

        @include screen($bp--phone) {
            float: none;
        }
    }
}
