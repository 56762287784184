[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    visibility: hidden !important;
    opacity: 0;
}

.gt-ng-wait {
    opacity: 0;
    transition: opacity 0.65s linear;
    &.gt-ng-ready {
        opacity: 1;
    }
}