.result-header {
    $header-height: 150px;
    $padding-width: 90px;
    $padding-height: 40px;
    $result-section-padding: 30px;
    display: none;
    padding-left: $site-padding;

    &.active {
        display: flex;
        align-items: center;
    }

    height: $header-height;
    flex-direction: row;
    background-color: $color--black;
    color: $color--floral-white;

    .result-count-section {
        width: calc(30% - 20px);
        background-color: $color--black;
        padding: $padding-height 0;
        display: flex;
        min-width: 260px;
    }

    $result-title-font-size: 28px;

    .results-title {
        @include font-serif-bold;
        font-size: rem($result-title-font-size);
        letter-spacing: 1px;
        display: inline-block;
    }

    .narrow-your-search {
        @include font-serif-bold;
        font-size: rem(14px);
        letter-spacing: 1px;
        display: block;
        line-height: 1.75;
        text-transform: uppercase;
    }

    .results-count {
        $result-count-size: 50px;
        $result-count-size-small: 36px;
        
        @include font-serif-bold;
        color: $color--floral-white;
        font-size: rem($result-count-size);
        position: relative;
        top: 0;
        display: inline-block;

        &:before {
            content: "";
            width: rem(50px);
            margin: 0 10px;
            height: rem($result-count-size * 0.5);
            border-top: solid 2px $color--floral-white;
            display: inline-block;
        }

        @media (min-width: 1023px) and (max-width: 1400px) {
            font-size: rem($result-count-size-small);

            &:before {
                width: rem($result-count-size-small);
                height: rem($result-count-size-small * 0.5);
            }
        }
    }

    .result-filter-section {
        width: 50%;
        padding: rem($result-section-padding);
        align-items: center;
        flex-wrap: wrap;
    }

    .result-filter {
        font-size: rem(20px);
        letter-spacing: 0.3px;
        margin-right: 16px;
        cursor: pointer;
        transition: $transition-text-color;
        display: inline-block;
        color: $color--brand-gold;

        &:hover {
            color: $color--floral-white;
        }

        &:after {
            content: 'x';
            margin-left: 8px;
        }
    }

    .clear-all {
        @include filler-link($background-color--initial:$color--floral-white, $color---initial:$color--brand-gold, $color---hover:$color--floral-white, $background-color--hover:$color--black);

        $padding-vertical: 2px;
        font-size: rem(20px);
        font-weight: bold;
        text-transform: none;
        padding: $padding-vertical 8px;
        position: relative;
        line-height: 1;
        top: $padding-vertical * 2;
    }

    .result-sort-section {
        width: 20%;
        padding: rem($result-section-padding);
    }

    .sort-label {
        font-size: rem(14px);
        @include font-serif;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $color--floral-white;
        line-height: rem(28px);
        font-weight: 700;
    }

    .sort-item {
        @include font-serif;
        font-size: rem(18px);
        color: $color--brand-gold;
        cursor: pointer;
        transition: $transition-text-color;

        + .sort-item {
            &:before {
                content: "|";
                margin: 0 10px 0 6px;
                color: $color--brand-gold;
            }
        }

        &.active {
            @include font-serif-bold;
        }
      
        &:hover {
           color: $color--floral-white;

           &:before {
               color: $color--brand-gold;
           }
        }
    }

    @include screen($bp--tablet) {
        $mobile-top-padding: 15px;

        &.active {
            display: flex;
            // display: block;
            flex-wrap: wrap;
            height: auto;
            overflow: hidden;
        }


        .result-count-section {
            padding: $padding-height $site-padding-mobile * 2;
            width: calc(100% - 4 * #{$site-padding-mobile});
            justify-content: center;
        }

        .result-filter-section {
            // display: inline-block;
            padding: $mobile-top-padding $site-padding-mobile;
            width: calc(50% - 2 * #{$site-padding-mobile});
            // float: left;
        }

        .result-sort-section {
            // display: inline-block;
            padding: $mobile-top-padding $site-padding-mobile;
            width: calc(50% - 2 * #{$site-padding-mobile});
            // float: left;
            text-align: right;
            min-height: 90px;
        }
    }

    ;

    @include screen($bp--phone) {
        $padding: 2rem;

        .result-filter-section {
            padding: $padding;
            display: block;
            width: 100%;
        }

        .result-sort-section {
            padding: 2rem;
            display: block;
            width: 100%;
            text-align: left;
            min-height: 0;
        }
    }

    ;
}
